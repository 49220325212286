//category: "Mathematics" , category: "AR | VR" , category: 'Science' , category: 'IoT | Electronics' , category: 'Geography' , category: 'Robotics' , category: 'Mathematics' , category: 'AI | ML' , 
import React from 'react';
import { FormattedMessage } from 'react-intl';
import VM from 'scratch-vm'; // Import the correct instance

// Create an instance of the Virtual Machine (Scratch VM)
const vm = new VM();

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import cadIconURL from './cad/file.png';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

import india from './india/india.png';
import india_small from './india/india_small.jpg';

import robocar from './robotic_car/robocar.png';
import rbocar_icon from './robotic_car/robocaricon.png';

import ml_extensions from './ml_extension/ml_extension.png';
import ml_extensionsmalls from './ml_extension/machine-learning.png';

import math2 from './math/math2.svg';
import maths1 from './math/maths1.jpg';

import speech1 from './speechrecognition/speech1.png';
import speech2 from './speechrecognition/speech2.png';

import spider from './spider_robo/Spider.png';
import spidericon from './spider_robo/spidericon.png';

import face from './face/image.png';

import arm from './Robotic arm/arm.png';
import armicon from './Robotic arm/armicon.png';

import objdetect from './object_detection/Object_detection.png';
import objdetectsmall from './object_detection/objct_detection_small.png'


import hand from './exp/hand.png';
import handpose from './exp/handpose.jpg';



import nlp1 from './nlp/nlp1.png';
import nlp2 from './nlp/nlp2.svg';

import cb1 from './cyberbully/cb1.jpg';
import cb2 from './cyberbully/cb2.jpg';

import cyber1 from './security/cyber1.jpg';
import cyber2 from './security/cyber2.jpg';


import charts from './table and charts/tableandcharts.png';
import chartsicon from './table and charts/chart.svg';

import arduinoBoard from './iot/arduinouno.png';
import sensorImg from './iot/sensors.png';
import actuatorImg from './iot/actuators.jpg';

import pythonImg from './coding/pythonimg.jpg';
import pythonIcon from './coding/pythonicon.png';

import blockImg from './coding/blockimg.jpg';
import blockIcon from '../../../components/gui/icon--code.svg'

import cogeo2 from './maths/cogeo2.jpg';
import cogeo1 from './maths/cogeo1.jpg';

import angle2 from './angles/angle2.png';
import angle1 from './angles/angle1.png';

import Fraction from './Fractions/Fraction.png';
import Fraction2 from './Fractions/Fraction2.jpg';

import puzzle from './puzzles/puzzle.jpg';
import puzzle2 from './puzzles/puzzle2.jpg';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Math 3D Shapes"
                description="Name for the 'CAD' extension"
                id="gui.extension.cad.name"
            />
        ),
        collaborator: 'StemWorld',
        extensionId: 'cad',
        iconURL: cadIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Create 3D models in Scratch."
                description="Description for the 'CAD' extension"
                id="gui.extension.cad.description"
            />
        ),
        featured: true,
        category: "Mathematics"
    },

    // {
    //     name: 'Indian Geography',
    //     extensionId: 'ingeo',
    //     collaborator: 'StemWorld',
    //     iconURL: india,
    //     insetIconURL: india_small,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Explore the Indian Geography."
    //             description="Description for Indian Geography"
    //             id="gui.extension.ingeo.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: true,
    //     // launchPeripheralConnectionFlow: true,
    //     // useAutoScan: false,
    //     // connectionIconURL: gdxforConnectionIconURL,
    //     // connectionSmallIconURL: gdxforConnectionSmallIconURL,
    //     // connectingMessage: (
    //     //     <FormattedMessage
    //     //         defaultMessage="Connecting"
    //     //         description="Message to help people connect to India Map."
    //     //         id="gui.extension.ingeo.connectingMessage"
    //     //     />
    //     // ),
    //     helpLink: 'https://scratch.mit.edu/wedo',
    //     category: 'All Extensions Section'
    // },

    {
        name: 'Robo Car',
        extensionId: 'car',
        collaborator: 'StemWorld',
        iconURL: robocar,
        insetIconURL: rbocar_icon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic car using block coding "
                description="Control robotic car using block coding"
                id="gui.extension.Scratch3MLextensionBlocks.car"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Spider',
        extensionId: 'spider',
        collaborator: 'StemWorld',
        iconURL: spider,
        insetIconURL: spidericon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic spider using block coding"
                description="Control robotic spider using block coding"
                id="gui.extension.Scratch3MLextensionBlocks.spider"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Machine Learning',
        extensionId: 'mlblock',
        collaborator: 'StemWorld',
        iconURL: ml_extensions,
        insetIconURL: ml_extensionsmalls,
        description: (
            <FormattedMessage
                defaultMessage="Teach your computer to learn."
                description="Teach your computer to learn."
                id="gui.extension.Scratch3TechableMachineExtension.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },

    {
        name: 'Hand pose',
        extensionId: 'HumanBody',
        collaborator: 'StemWorld',
        iconURL: handpose,
        insetIconURL: hand,
        description: (
            <FormattedMessage
                defaultMessage="joy doing hand pose."
                description="joy"
                id="gui.extension.Scratch3HumanBodyExtension.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    // {
    //     name: 'Mathematics',
    //     extensionId: 'MathJoy',
    //     collaborator: 'StemWorld',
    //     iconURL: maths1,
    //     insetIconURL: math2,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Do math and have fun."
    //             description="Enjoy"
    //             id="gui.extension.math.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: true,
    //     // launchPeripheralConnectionFlow: true, 
    //     // useAutoScan: false,
    //     // connectionIconURL: gdxforConnectionIconURL,
    //     // connectionSmallIconURL: gdxforConnectionSmallIconURL,
    //     // connectingMessage: (
    //     //     <FormattedMessage
    //     //         defaultMessage="Connecting"
    //     //         description="Message to help people connect to India Map."
    //     //         id="gui.extension.ingeo.connectingMessage"
    //     //     />
    //     // ),
    //     helpLink: 'https://scratch.mit.edu/wedo',
    //     category: 'All Extensions Section'
    // },

    {
        name: 'Speech Recognition',
        extensionId: 'Speech_Recognition',
        collaborator: 'StemWorld',
        iconURL: speech2,
        insetIconURL: speech1,
        description: (
            <FormattedMessage
                defaultMessage="We have ears too"
                description="Enjoy"
                id="gui.extension.ar_vr.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Face Detection',
        extensionId: 'face',
        collaborator: 'StemWorld',
        iconURL: face,
        insetIconURL: ml_extensionsmalls,
        description: (
            <FormattedMessage
                defaultMessage="Classify image and Pose Using TM "
                description="Classify Image & Pose using TM"
                id="gui.extension.Scratch3ArduinoBlocks.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Robotic Arm',
        extensionId: 'arm',
        collaborator: 'StemWorld',
        iconURL: arm,
        insetIconURL: armicon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic arm using block coding "
                description="Control robotic arm using block coding"
                id="gui.extension.Scratch3ArduinoBlocks.arm"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3',
        category: 'All Extensions Section'
    },

    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost',
        category: 'All Extensions Section'
    },
    {
        name: 'Natural Language Processing',
        extensionId: 'NLP',
        collaborator: 'StemWorld',
        iconURL: nlp1,
        insetIconURL: nlp2,
        description: (
            <FormattedMessage
                defaultMessage="I know your Language"
                description="Enjoy"
                id="gui.extension.nlp.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Object Detection',
        extensionId: 'object',
        collaborator: 'StemWorld',
        iconURL: objdetect,
        insetIconURL: objdetectsmall,
        description: (
            <FormattedMessage
                defaultMessage="Detect Objects"
                description="Identify different objects"
                id="gui.extension.Scratch3Objectdetecuionblocks.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    // {
    //     name: 'Cyber Security',
    //     extensionId: 'security',
    //     collaborator: 'StemWorld',
    //     iconURL: cyber1,
    //     insetIconURL: cyber2,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Password safety"
    //             description="Identify different objects"
    //             id="gui.extension.security.EXTENSION_ID"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: true,
    //     // launchPeripheralConnectionFlow: true,
    //     // useAutoScan: false,
    //     // connectionIconURL: gdxforConnectionIconURL,
    //     // connectionSmallIconURL: gdxforConnectionSmallIconURL,
    //     // connectingMessage: (
    //     //     <FormattedMessage
    //     //         defaultMessage="Connecting"
    //     //         description="Message to help people connect to India Map."
    //     //         id="gui.extension.ingeo.connectingMessage"
    //     //     />
    //     // ),
    //     helpLink: 'https://scratch.mit.edu/wedo',
    //     category: 'All Extensions Section'
    // },
    // {
    //     name: 'Cyber Security',
    //     extensionId: 'bullying',
    //     collaborator: 'StemWorld',
    //     iconURL: cb2,
    //     insetIconURL: cb1,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Cyber bullying"
    //             description="Identify different objects"
    //             id="gui.extension.bullying.EXTENSION_ID"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: false,
    //     internetConnectionRequired: true,
    //     // launchPeripheralConnectionFlow: true,
    //     // useAutoScan: false,
    //     // connectionIconURL: gdxforConnectionIconURL,
    //     // connectionSmallIconURL: gdxforConnectionSmallIconURL,
    //     // connectingMessage: (
    //     //     <FormattedMessage
    //     //         defaultMessage="Connecting"
    //     //         description="Message to help people connect to India Map."
    //     //         id="gui.extension.ingeo.connectingMessage"
    //     //     />
    //     // ),
    //     helpLink: 'https://scratch.mit.edu/wedo',
    //     category: 'All Extensions Section'
    // },
    {
        name: 'Charts and Table',
        extensionId: 'chart',
        collaborator: 'StemWorld',
        iconURL: charts,
        insetIconURL: chartsicon,
        description: (
            <FormattedMessage
                defaultMessage="Chart and Tables"
                description="Create tables and charts"
                id="gui.extension.chart.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: "All Extensions Section"
    },

    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Math 3D Shapes"
    //             description="Name for the 'CAD' extension"
    //             id="gui.extension.cad.name"
    //         />
    //     ),
    //     collaborator: 'StemWorld',
    //     extensionId: 'cad',
    //     iconURL: cadIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Create 3D models in Scratch."
    //             description="Description for the 'CAD' extension"
    //             id="gui.extension.cad.description"
    //         />
    //     ),
    //     featured: true,
    //     category: "All Extensions Section"
    // },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true,
        category: "All Extensions Section"
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true,
        category: 'All Extensions Section'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true,
        category: 'All Extensions Section'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true,
        category: 'All Extensions Section'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true,
        category: 'All Extensions Section'
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        collaborator: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the 'Makey Makey' extension"
                id="gui.extension.makeymakey.description"
            />
        ),
        featured: true,
        category: 'All Extensions Section'
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit',
        category: 'All Extensions Section'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3',
        category: 'All Extensions Section'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'All Extensions Section'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier',
        category: 'All Extensions Section'
    },
    {
        name: 'Arduino Uno',
        extensionId: 'arduino',
        collaborator: 'StemWorld',
        iconURL: arduinoBoard,
        insetIconURL:arduinoBoard,
        description: (
            <FormattedMessage
                defaultMessage="Arduino Uno Board"
                description="Arduino Uno Extension"
                id="gui.extension.arduino.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: "All Extensions Section"
    },

    // -------------------------------------------------------------------------------------------------------------------

    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3',
        category: 'Science'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'IoT | Electronics'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier',
        category: 'IoT | Electronics'
    },
    {
        name: 'Indian Geography',
        extensionId: 'ingeo',
        collaborator: 'StemWorld',
        iconURL: india,
        insetIconURL: india_small,
        description: (
            <FormattedMessage
                defaultMessage="Explore the Indian Geography."
                description="Description for Indian Geography"
                id="gui.extension.ingeo.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Geography'
    },
    {
        name: 'Indian Geography',
        extensionId: 'india',
        collaborator: 'StemWorld',
        iconURL: india,
        insetIconURL:rbocar_icon ,
        onClick: () => {
            console.log('Extension clicked, performing action...');
            // Here, trigger any specific action like rendering a modal or adding content to DOM
            // Use state or another method to manage rendering of ExtensionWithSB3Loading in your app
        },
        description: (
            <FormattedMessage
                defaultMessage="Explore the Indian Geography."
                description="Description for Indian Geography"
                id="gui.extension.ingeo.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Geography'
        },

    {
        name: 'Robo Car',
        extensionId: 'car',
        collaborator: 'StemWorld',
        iconURL: robocar,
        insetIconURL: rbocar_icon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic car using block coding "
                description="Control robotic car using block coding"
                id="gui.extension.Scratch3MLextensionBlocks.car"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Robotics'
    },
    {
        name: 'Spider',
        extensionId: 'spider',
        collaborator: 'StemWorld',
        iconURL: spider,
        insetIconURL: spidericon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic spider using block coding"
                description="Control robotic spider using block coding"
                id="gui.extension.Scratch3MLextensionBlocks.spider"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Robotics'
    },
    {
        name: 'Machine Learning',
        extensionId: 'mlblock',
        collaborator: 'StemWorld',
        iconURL: ml_extensions,
        insetIconURL: ml_extensionsmalls,
        description: (
            <FormattedMessage
                defaultMessage="Teach your computer to learn"
                description="Teach your computer to learn"
                id="gui.extension.mlblock.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },

    {
        name: 'Hand pose',
        extensionId: 'HumanBody',
        collaborator: 'StemWorld',
        iconURL: handpose,
        insetIconURL: hand,
        description: (
            <div onClick={() => handleStartSelectingFileUpload()}>
                
                <FormattedMessage
                    defaultMessage="joy doing hand pose."
                    description="joy"
                    id="gui.extension.Scratch3HumanBodyExtension.description"
                />
            </div>
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },
    {
        name: 'Mathematics',
        extensionId: 'MathJoy',
        collaborator: 'StemWorld',
        iconURL: maths1,
        insetIconURL: math2,
        description: (
            <FormattedMessage
                defaultMessage="Do math and have fun."
                description="Enjoy"
                id="gui.extension.math.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Mathematics'
    },

    {
        name: 'Speech Recognition',
        extensionId: 'Speech_Recognition',
        collaborator: 'StemWorld',
        iconURL: speech2,
        insetIconURL: speech1,
        description: (
            <FormattedMessage
                defaultMessage="We have ears too"
                description="Enjoy"
                id="gui.extension.ar_vr.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },
    {
        name: 'Face Detection',
        extensionId: 'face',
        collaborator: 'StemWorld',
        iconURL: face,
        insetIconURL: ml_extensionsmalls,
        description: (
            <FormattedMessage
                defaultMessage="Classify image and Pose Using TM "
                description="Classify Image & Pose using TM"
                id="gui.extension.Scratch3ArduinoBlocks.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },
    {
        name: 'Robotic Arm',
        extensionId: 'arm',
        collaborator: 'StemWorld',
        iconURL: arm,
        insetIconURL: armicon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic arm using block coding "
                description="Control robotic arm using block coding"
                id="gui.extension.Scratch3ArduinoBlocks.arm"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Robotics'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3',
        category: 'Robotics'
    },

    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost',
        category: 'Robotics'
    },
    {
        name: 'Natural Language Processing',
        extensionId: 'NLP',
        collaborator: 'StemWorld',
        iconURL: nlp1,
        insetIconURL: nlp2,
        description: (
            <FormattedMessage
                defaultMessage="I know your Language"
                description="Enjoy"
                id="gui.extension.nlp.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },
    {
        name: 'Object Detection',
        extensionId: 'object',
        collaborator: 'StemWorld',
        iconURL: objdetect,
        insetIconURL: objdetectsmall,
        description: (
            <FormattedMessage
                defaultMessage="Detect Objects"
                description="Identify different objects"
                id="gui.extension.Scratch3Objectdetecuionblocks.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'AI | ML'
    },
    {
        name: 'Cyber Security',
        extensionId: 'security',
        collaborator: 'StemWorld',
        iconURL: cyber1,
        insetIconURL: cyber2,
        description: (
            <FormattedMessage
                defaultMessage="Password safety"
                description="Identify different objects"
                id="gui.extension.security.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Cybersecurity'
    },
    {
        name: 'Cyber Security',
        extensionId: 'bullying',
        collaborator: 'StemWorld',
        iconURL: cb2,
        insetIconURL: cb1,
        description: (
            <FormattedMessage
                defaultMessage="Cyber bullying"
                description="Identify different objects"
                id="gui.extension.bullying.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Cybersecurity'
    },
    {
        name: 'Charts and Table',
        extensionId: 'chart',
        collaborator: 'StemWorld',
        iconURL: charts,
        insetIconURL: chartsicon,
        description: (
            <FormattedMessage
                defaultMessage="Chart and Tables"
                description="Create tables and charts"
                id="gui.extension.chart.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: "Mathematics"
    },
    {
        name: 'Maths',
        extensionId: 'co_ordinateGeometry',
        collaborator: 'StemWorld',
        iconURL: cogeo2,
        insetIconURL: cogeo1,
        description: (
            <FormattedMessage
                defaultMessage="Maths Visualization"
                description="Simply Math"
                id="gui.extension.simpleMath.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
       
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Mathematics'
    },
    {
        name: 'Block Coding',
        extensionId: 'blocks',
        collaborator: 'StemWorld',
        iconURL: blockImg,
        insetIconURL: blockIcon,
        description: (
            <FormattedMessage
                defaultMessage="Coding Blocks"
                description="Coding blocks"
                id="gui.extension.blocks.description"
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        helpLink: 'https://scratch.mit.edu/wedo',
        category: "Coding"
    },
    {
        name: 'Python Coding',
        extensionId: 'python',
        collaborator: 'StemWorld',
        iconURL: pythonImg,
        insetIconURL: pythonIcon,
        description: (
            <FormattedMessage
                defaultMessage="Python ENV"
                description="Actuator blocks"
                id="gui.extension.python.description"
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        helpLink: 'https://scratch.mit.edu/wedo',
        category: "Coding"
    },

    {
        name: 'Angles',
        extensionId: 'angles',
        collaborator: 'StemWorld',
        iconURL: angle2,
        insetIconURL: angle1,
        description: (
            <FormattedMessage
                defaultMessage="Angles Visualization"
                description="Learn angles"
                id="gui.extension.angles.EXTENSION_ID"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
       
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Mathematics'
    },
    {
        name: 'Fractions',
        extensionId: 'Fractions',
        collaborator: 'StemWorld',
        iconURL: Fraction,
        insetIconURL: Fraction2,  
        description: (
            <FormattedMessage
                defaultMessage=" Learn Fractions."
                description="Fractions"
                id="gui.extension.Fractions.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Mathematics'
    },
    {
        name: 'Balance scale',
        extensionId: 'puzzles',
        collaborator: 'StemWorld',
        iconURL: puzzle,
        insetIconURL: puzzle2,
        description: (
            <FormattedMessage
                defaultMessage="Do and have fun."
                description="joy"
                id="gui.extension.puzzles.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true, 
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'Mathematics'
    },

    {
        name: 'Python Robo Car',
        extensionId: 'python_robo_car',
        collaborator: 'StemWorld',
        iconURL: robocar,
        insetIconURL: rbocar_icon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic car using python block coding "
                description="Control robotic car using python block coding"
                id="gui.extension.Scratch3MLextensionBlocks.python_robo_car"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'PythonRobotics'
    },

    {
        name: 'Python Robotic Arm',
        extensionId: 'python_robo_arm',
        collaborator: 'StemWorld',
        iconURL: arm,
        insetIconURL: armicon,
        description: (
            <FormattedMessage
                defaultMessage="Control robotic arm using python block coding "
                description="Control robotic arm using python block coding"
                id="gui.extension.Scratch3ArduinoBlocks.python_robo_arm"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: false,
        // connectionIconURL: gdxforConnectionIconURL,
        // connectionSmallIconURL: gdxforConnectionSmallIconURL,
        // connectingMessage: (
        //     <FormattedMessage
        //         defaultMessage="Connecting"
        //         description="Message to help people connect to India Map."
        //         id="gui.extension.ingeo.connectingMessage"
        //     />
        // ),
        helpLink: 'https://scratch.mit.edu/wedo',
        category: 'PythonRobotics'
    },
];

