const BlockType = require('../../extension-support/block-type');
const ArgumentType = require('../../extension-support/argument-type');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
let initializedPins = new Set();
let definedFunctions = new Set();

class Scratch3DisplayBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'display',
            name: 'Display Modules',
            color1: '#d62222',
            
            blocks: [
                {
                    opcode: 'initializeDisplay',
                    blockType: BlockType.COMMAND,
                    text: 'Initialize OLED Display',
                    arguments: {}
                },
                {
                    opcode: 'clearDisplay',
                    blockType: BlockType.COMMAND,
                    text: 'Clear Display',
                    arguments: {}
                },
                {
                    opcode: 'displayOLED',
                    blockType: BlockType.COMMAND,
                    text: 'Display OLED text [text] size [textsize] at position x:[crpoint1] y:[crpoint2]',
                    arguments: {
                        text: {
                            type: ArgumentType.STRING,
                            defaultValue: 'Hello'
                        },
                        textsize: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        },
                        crpoint1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        crpoint2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        }
                    }
                }
            ],
            menus: {
                
            }
        };
    }

    initializeDisplay() {
        // Add necessary header files
        defineCode += '#include <SPI.h>\n';
        defineCode += '#include <Wire.h>\n';
        defineCode += '#include <Adafruit_GFX.h>\n';
        defineCode += '#include <Adafruit_SSD1306.h>\n';
        
        // Define OLED reset pin
        defineCode += '#define OLED_RESET 4\n';
        defineCode += 'Adafruit_SSD1306 display(OLED_RESET);\n';
        
        // Add initialization code to setup
        setupCode += 'display.begin(SSD1306_SWITCHCAPVCC, 0x3C);\n';
        
        this.accumulateCode();
        this.resetCode();
    }

    clearDisplay() {
        const clearCode = 'display.clearDisplay();\n';
        
        // Add the clear display code to either setup or loop based on context
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += clearCode;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += clearCode;
        }
        
        this.accumulateCode();
        this.resetCode();
    }

    displayOLED(args) {
        // Define the display_oled function if not already defined
        if (!definedFunctions.has('display_oled')) {
            bodyCode += `void display_oled(int textsize,int crpoint1, int crpoint2, String text){
                display.setTextSize(textsize);
                display.setTextColor(WHITE);
                display.setCursor(crpoint1,crpoint2);
                display.println(text);
                display.display();
            }\n`;
            definedFunctions.add('display_oled');
        }

        // Add function call to appropriate context
        const functionCall = `display_oled(${args.textsize},${args.crpoint1},${args.crpoint2},"${args.text}");\n`;
        
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += functionCall;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += functionCall;
        }

        this.accumulateCode();
        this.resetCode();
    }

    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }

    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }

    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins = new Set();
        definedFunctions = new Set();
    }
}

module.exports = Scratch3DisplayBlocks;