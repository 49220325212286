// src/utils/apiClient.js

import axios from 'axios';
import { refreshAccessToken, logoutUser } from './authService';

const apiClient = axios.create({
  baseURL: 'https://api.stemverse.app',   //'https://api.stemverse.app'
  withCredentials: true,             // Needed to send cookies with requests
});

// Request Interceptor to include access token in every request
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor to handle access token expiration and automatic logout
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token if the access token expired
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
            localStorage.setItem('accessToken', newAccessToken);
            apiClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            return apiClient(originalRequest);
          } else {
            // If no token received, log out
            logoutUser();
            return Promise.reject(new Error('Session expired'));
          }
      } catch (err) {
        // Refresh token has expired, so log the user out
        console.error('Refresh token expired. Logging out...');
        logoutUser();
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
