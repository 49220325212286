import React from 'react';
import generateAndUploadCode from './ArduinoUploader';
import { connect } from 'react-redux';
import img1 from './boardsimages/arduinouno.png';
import img2 from './boardsimages/Mega.1.png';
import img3 from './boardsimages/nano.png';
import img4 from './boardsimages/esp32.png';
import img5 from './boardsimages/twatch.svg';
import img6 from './boardsimages/robotics.png';
// import img6 from './boardsimages/microbit.svg';
// import img7 from './boardsimages/evive.svg';
// import img8 from './boardsimages/legoev3.svg';
// import img9 from './boardsimages/legoboost.svg';
// import img10 from './boardsimages/quarky.svg';
// import img11 from './boardsimages/wizbot.svg';
import closeimg from './icon--close.svg';

const boards = [
    { name: 'Arduino Uno', img: img1 },
    { name: 'Arduino Mega', img: img2 },
    { name: 'Arduino Nano', img: img3 },
    { name: 'ESP32', img: img4 },
    { name: 'T-Watch', img: img5 },
    { name: 'Stemverse', img: img6 },
    // { name: 'micro:bit', img: img6 },
    // { name: 'Evive', img: img7 },
    // { name: 'LEGO EV3', img: img8 },
    // { name: 'LEGO BOOST', img: img9 },
    // { name: 'Quarky', img: img10 },
    // { name: 'Wizbot', img: img11 },
];

const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalStyle = {
    background: 'white',
    borderRadius: '8px',
    width: '35%',
    height: '70%',
    maxWidth: '600px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
};

const headerTitleStyle = {
    flexGrow: 1,
    textAlign: 'center',
    marginLeft: '-1px',
    fontSize: '40px',
    color: "white"
};

const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#7850d4',
    borderBottom: '1px solid #ddd',
};

const closeButtonDivStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const modalBodyStyle = {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
};

const closeButtonStyle = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
};

const boardGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', // Adjusted for smaller cards
    gap: '10px',
};

const boardItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px', // Added padding for the smaller card effect
    transition: 'transform 0.2s, border 0.2s', // Smooth transition effect
};

const boardImgStyle = {
    width: '60px', // Smaller size for the images
    height: '60px',
    objectFit: 'contain',
    display: 'block',
    marginBottom: '5px', // Less margin for a compact look
};

const boardNameStyle = {
    fontSize: '14px', // Smaller font size
    fontFamily: 'Helvetica, sans-serif',
};

class BoardSelectionModal extends React.Component {
    handleBoardClick(board) {
        // Update localStorage with the selected board
        localStorage.setItem('selectedBoard', JSON.stringify(board));

        this.props.onBoardSelect(board);
        this.props.onClose(); // Close the modal after selecting the board

        if (board.name === 'Arduino Uno') {
            this.props.vm.extensionManager.loadExtensionURL('arduino');
            this.props.vm.extensionManager.loadExtensionURL('actuators');
            this.props.vm.extensionManager.loadExtensionURL('sensors');
            // this.props.vm.extensionManager.loadExtensionURL('display');
        } else if (board.name === 'micro:bit') {
            this.props.vm.extensionManager.loadExtensionURL('microbit');
        } else if (board.name === 'LEGO EV3') {
            this.props.vm.extensionManager.loadExtensionURL('ev3');
        } else if (board.name === 'LEGO BOOST') {
            this.props.vm.extensionManager.loadExtensionURL('boost');
        } else if (board.name === 'Stemverse') {
            this.props.vm.extensionManager.loadExtensionURL('stemengine');
            this.props.vm.extensionManager.loadExtensionURL('actuators');
            this.props.vm.extensionManager.loadExtensionURL('sensors');
            // this.props.vm.extensionManager.loadExtensionURL('display');
        } else if (board.name === 'ESP32') {
            this.props.vm.extensionManager.loadExtensionURL('esp32');
            this.props.vm.extensionManager.loadExtensionURL('actuators');
            this.props.vm.extensionManager.loadExtensionURL('display');
            this.props.vm.extensionManager.loadExtensionURL('sensors');
        }
    }

    render() {
        return (
            <div style={modalOverlayStyle}>
                <div style={modalStyle}>
                    <div style={modalHeaderStyle}>
                        <h2 style={headerTitleStyle}>Select a Board</h2>
                        <div style={closeButtonDivStyle}>
                            <button style={closeButtonStyle} onClick={this.props.onClose}>
                                <img style={{ height: "25px", width: "25px", color: "#8DA765" }} src={closeimg} alt="Close" />
                            </button>
                        </div>
                    </div>
                    <div style={modalBodyStyle}>
                        <div style={boardGridStyle}>
                            {boards.map((board, index) => (
                                <div
                                    key={index}
                                    style={boardItemStyle}
                                    onClick={() => this.handleBoardClick(board)}
                                    onMouseEnter={(e) => e.currentTarget.style.border = '2px solid #000080'}
                                    onMouseLeave={(e) => e.currentTarget.style.border = '1px solid #ddd'}
                                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'} // Scale effect on hover
                                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                >
                                    <img src={board.img} alt={board.name} style={boardImgStyle} />
                                    <span style={boardNameStyle}>{board.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BoardSelectionModal.defaultProps = {
    onBoardSelect: () => { },
    onClose: () => { } // Add default onClose function to avoid errors if not provided
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm // Adjust this according to your state structure
});

export default connect(mapStateToProps)(BoardSelectionModal);
