const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');

class AngleRepresentation {
    constructor(runtime) {
        this.runtime = runtime;
        this.canvas = null;
        // Global object to store coefficients
this.coefficients = { SIDE_A: 3, SIDE_B: 4, HYPOTENUSE: 5 };
    }

    getInfo() {
        return {
            id: 'angleRepresentation',
            name: 'Angle Representation',
            // colours to use for your extension blocks
            color1: '#AD88C6',
            color2: '#AD88C6',
            blocks: [
                {
                    opcode: 'drawAngle',
                    blockType: BlockType.COMMAND,
                    text: 'Draw angle [ANGLE]°',
                    arguments: {
                        ANGLE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 45
                        }
                    }
                },
                {
                    opcode: 'showAngleType',
                    blockType: BlockType.COMMAND,
                    text: 'Show type of drawn angle',
                },
               
                {
                    opcode: 'drawTriangleWithAngle',
                    blockType: BlockType.COMMAND,
                    text: 'Draw triangle with angle [ANGLE]°',
                    arguments: {
                        ANGLE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 45
                        }
                    }
                },
                {
                    opcode: 'triangleType',
                    blockType: BlockType.COMMAND,
                    text: 'Show type of Triangle',
                },
                {
                    opcode: 'setTriangleAngles',
                    blockType: BlockType.COMMAND,
                    text: 'Set angle1 [ANGLE1] and angle2 [ANGLE2]',
                    arguments: {
                        ANGLE1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 60
                        },
                        ANGLE2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 60
                        }
                    }
                },
                {
                    opcode: 'calculateThirdAngle',
                    blockType: BlockType.COMMAND,
                    text: 'Calculate third angle'
                },
                {
                    opcode: 'drawPolygon',
                    blockType: BlockType.COMMAND,
                    text: 'Draw polygon with [SIDES] sides',
                    arguments: {
                        SIDES: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3,
                        },
                    },
                },
                {
                    opcode: 'calculateInteriorAngle',
                    blockType: BlockType.COMMAND,
                    text: 'Interior angle of polygon with [SIDES] sides',
                    arguments: {
                        SIDES: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3,
                        },
                    },
                },
                {
                    opcode: 'calculateExteriorAngle',
                    blockType: BlockType.COMMAND,
                    text: 'calculate exterior angle for [SIDES] sides',
                    arguments: {
                        SIDES: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3
                        }
                    }
                },
                // Block definition for drawing four right-angle triangles
                {
                    opcode: 'drawFourRightAngleTriangles',
                    blockType: BlockType.COMMAND,
                    text: 'Draw 4 Right-Angle Triangles with sides [SIDE_A], [SIDE_B] and hypotenuse [HYPOTENUSE]',
                    arguments: {
                        SIDE_A: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3
                        },
                        SIDE_B: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 4
                        },
                        HYPOTENUSE: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 5
                        }
                    }
                },
                {
                    opcode: 'drawPythagoreanProof',
                    blockType: BlockType.COMMAND,
                    text: 'Draw combined triangles and square [SIDE_A] [SIDE_B] [HYPOTENUSE]',
                    arguments: {
                        SIDE_A: { type: ArgumentType.NUMBER, defaultValue: 3 },
                        SIDE_B: { type: ArgumentType.NUMBER, defaultValue: 4 },
                        HYPOTENUSE: { type: ArgumentType.NUMBER, defaultValue: 5 }
                    }
                },
                {
                    opcode: 'drawRectanglesWithTriangles',
                    blockType: BlockType.COMMAND,
                    text: 'Draw Rectangles with Triangles [SIDE_A] [SIDE_B] [HYPOTENUSE]',
                    arguments: {
                        SIDE_A: { type: ArgumentType.NUMBER, defaultValue: 3 },
                        SIDE_B: { type: ArgumentType.NUMBER, defaultValue: 4 },
                        HYPOTENUSE: { type: ArgumentType.NUMBER, defaultValue: 5 }
                    }
                },
                {
                    opcode: 'displayProof',
                    blockType: BlockType.COMMAND,
                    text: 'Dispaly the squares [SIDE_A] [SIDE_B] [HYPOTENUSE]',
                    arguments: {
                        SIDE_A: { type: ArgumentType.NUMBER, defaultValue: 3 },
                        SIDE_B: { type: ArgumentType.NUMBER, defaultValue: 4 },
                        HYPOTENUSE: { type: ArgumentType.NUMBER, defaultValue: 5 }
                    }
                },
                // Block for drawing the right-angle triangle
                {
                    opcode: 'generateTriangle',
                    blockType: BlockType.COMMAND,
                    text: 'Draw Right angle triangle of a: [SIDE_A] b: [SIDE_B]',
                    arguments: {
                        SIDE_A: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3
                        },
                        SIDE_B: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 4
                        }
                    }
                },

                // Block for calculating the hypotenuse
                {
                    opcode: 'calculateHypotenuse',
                    blockType: BlockType.COMMAND,
                    text: 'Calculate hypotenuse for sides a: [SIDE_A] b: [SIDE_B]',
                    arguments: {
                        SIDE_A: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 3
                        },
                        SIDE_B: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 4
                        }
                    }
                },
                {
                    opcode: '_clearCanvas',
                    blockType: BlockType.COMMAND,
                    text: 'Clear Canvas'
                },          
            ]
        };
    }
    _initializeCanvas() {
        if (!this.canvas) {
            console.log('Initializing canvas...');
            this.canvas = document.createElement('canvas');
            this.canvas.width = 480;
            this.canvas.height = 360;
            this.canvas.style.position = 'absolute';
            this.canvas.style.top = '90px';
            this.canvas.style.right = '10px';
            this.canvas.style.left = 'auto';
            this.canvas.style.pointerEvents = 'none';
            this.canvas.style.zIndex = '10';
            this.canvas.style.border = '1px solid black';
            document.body.appendChild(this.canvas);
            this.context = this.canvas.getContext('2d');
        }
        return this.canvas;
    }


    // Clear the canvas
    _clearCanvas() {
        if (this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    }

    drawAngle(args) {
        const angle = args.ANGLE;
        this._drawCanvas(angle);
    }

    showAngleType() {
        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');
        const angle = this.lastDrawnAngle;

        if (angle !== undefined) {
            let angleType = '';
            if (angle < 90) angleType = 'Acute Angle';
            else if (angle === 90) angleType = 'Right Angle';
            else if (angle < 180) angleType = 'Obtuse Angle';
            else if (angle === 180) angleType = 'Straight Angle';
            else angleType = 'Reflex Angle';

            // Display angle type on canvas
            ctx.font = '20px Arial';
            ctx.fillStyle = 'purple'; // Change color to purple
            ctx.fillText(angleType, canvas.width / 2 - 50, canvas.height - 100); // Adjusted y-coordinate
        }
    }

    drawTriangleWithAngle(args) {
        const angle = args.ANGLE;

        // Save the angle specifically for the triangle
        this.triangleAngle = angle;

        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');

        const baseLength = 150;

        // Adjust the triangle's position to be below the mid of the canvas
        const centerX = canvas.width / 2; // Center horizontally
        const centerY = (canvas.height / 2) + 60; // Move 100px below the middle

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate triangle vertices
        const radian = (angle * Math.PI) / 180;
        const x1 = centerX - baseLength / 2;
        const y1 = centerY;
        const x2 = centerX + baseLength / 2;
        const y2 = centerY;
        const x3 = x1 + baseLength * Math.cos(radian);
        const y3 = y1 - baseLength * Math.sin(radian);

        // Draw the triangle
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineTo(x3, y3);
        ctx.closePath();

        ctx.strokeStyle = '#4CAF50';
        ctx.lineWidth = 3;
        ctx.stroke();

        // Draw the angle arc at the left base corner
        const arcRadius = 30;
        const arcStartAngle = Math.atan2(y3 - y1, x3 - x1);
        const arcEndAngle = Math.atan2(y2 - y1, x2 - x1);

        ctx.beginPath();
        ctx.arc(x1, y1, arcRadius, arcStartAngle, arcEndAngle, false);
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Display the given angle value near the arc
        const labelX = x1 + (arcRadius + 10) * Math.cos((arcStartAngle + arcEndAngle) / 2);
        const labelY = y1 + (arcRadius + 10) * Math.sin((arcStartAngle + arcEndAngle) / 2);
        ctx.font = '16px Arial';
        ctx.fillStyle = 'blue';
        ctx.fillText(`${angle}°`, labelX, labelY);
    }
    _drawCanvas(angle) {
        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2 + 50; // Adjusted to place just below the middle
        const lineLength = 150;

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw horizontal base line (fixed)
        ctx.beginPath();
        ctx.moveTo(centerX - lineLength, centerY);
        ctx.lineTo(centerX + lineLength, centerY);
        ctx.strokeStyle = '#4CAF50'; // Green color for horizontal line
        ctx.lineWidth = 3;
        ctx.stroke();

        // Calculate the end position for the angled line
        const radian = (angle * Math.PI) / 180;
        const endX = centerX + lineLength * Math.cos(radian);
        const endY = centerY - lineLength * Math.sin(radian);

        // Draw angled line
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = '#FF5722'; // Orange color for angled line
        ctx.lineWidth = 3;
        ctx.stroke();

        // Draw the angle arc
        const arcRadius = 40;
        ctx.beginPath();
        ctx.arc(centerX, centerY, arcRadius, 0, -radian, true);
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Display the angle value
        ctx.font = '16px Arial';
        ctx.fillStyle = 'blue';
        const labelX = centerX + (arcRadius + 20) * Math.cos(-radian / 2);
        const labelY = centerY + (arcRadius + 20) * Math.sin(-radian / 2);
        ctx.fillText(`${angle}°`, labelX, labelY);

        // Save the last drawn angle for use in showAngleType
        this.lastDrawnAngle = angle;

    }
    triangleType() {
        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');

        // Retrieve the triangle-specific angle
        const angle = this.triangleAngle;

        if (angle !== undefined) {
            let triangleType = '';

            // Determine the triangle type based on the angle
            if (angle < 90) {
                triangleType = 'Acute Triangle';
            } else if (angle === 90) {
                triangleType = 'Right Triangle';
            } else if (angle < 180) {
                triangleType = 'Obtuse Triangle';
            } else {
                triangleType = 'Invalid Triangle'; // Handle invalid angles
            }

            // Clear space below the triangle to avoid overlapping text
            const textY = canvas.height / 2 + 100;
            ctx.clearRect(0, textY - 30, canvas.width, 50);

            // Display the triangle type below the triangle
            ctx.font = '20px Arial';
            ctx.fillStyle = 'purple';
            const textX = canvas.width / 2 - ctx.measureText(triangleType).width / 2;
            ctx.fillText(triangleType, textX, textY);
        } else {
            console.error('No triangle angle found! Please draw the triangle first.');
        }
    }
    setTriangleAngles(args) {
        const angle1 = parseFloat(args.ANGLE1);
        const angle2 = parseFloat(args.ANGLE2);

        // Validation: Sum of angles must be less than 180
        if (angle1 + angle2 >= 180) {
            console.error('Invalid angles! The sum of angle1 and angle2 must be less than 180.');
            return;
        }

        // Store the angles
        this.triangleAngle1 = angle1;
        this.triangleAngle2 = angle2;

        // Redraw the triangle with the given angles
        this._drawTriangleWithAngles(angle1, angle2);

        console.log(`Set angle1 to ${angle1} and angle2 to ${angle2}.`);
    }
    _drawTriangleWithAngles(angle1, angle2) {
        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

        // Define triangle base and height
        const base = 200;
        const height = Math.tan((angle1 * Math.PI) / 180) * (base / 2);

        // Define points of the triangle
        const bottomLeftX = canvas.width / 2 - base / 2;
        const bottomLeftY = canvas.height / 2 + height / 2;
        const bottomRightX = canvas.width / 2 + base / 2;
        const bottomRightY = bottomLeftY;
        const topX = canvas.width / 2;
        const topY = canvas.height / 2 - height / 2;

        // Draw the triangle
        ctx.beginPath();
        ctx.moveTo(bottomLeftX, bottomLeftY);
        ctx.lineTo(bottomRightX, bottomRightY);
        ctx.lineTo(topX, topY);
        ctx.closePath();

        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Display the angles inside the triangle
        ctx.font = '16px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(`Angle1: ${angle1}°`, bottomLeftX + 10, bottomLeftY - 10);
        ctx.fillText(`Angle2: ${angle2}°`, bottomRightX - 80, bottomRightY - 10);

        const thirdAngle = 180 - (angle1 + angle2); // Calculate third angle
        // ctx.fillText(`Angle3: ${thirdAngle}°`, topX - 40, topY + 30);
    }
    calculateThirdAngle() {
        if (this.triangleAngle1 === undefined || this.triangleAngle2 === undefined) {
            console.error('Angles not set! Use the "Set angle1 and angle2" block first.');
            return 'Error';
        }

        // Calculate the third angle
        const thirdAngle = 180 - (this.triangleAngle1 + this.triangleAngle2);

        if (thirdAngle <= 0) {
            console.error('Invalid angles! Cannot calculate a valid third angle.');
            return 'Error';
        }

        // Draw third angle value on the top-right of the canvas
        const canvas = this._initializeCanvas();
        const ctx = canvas.getContext('2d');

        // Clear any previous text in the area
        const textX = canvas.width - 170; // Move further to the left
        const textY = 50; // Top margin
        ctx.clearRect(textX - 20, textY - 20, 180, 50);

        // Display the calculated third angle in green color
        ctx.font = '20px Arial';
        ctx.fillStyle = 'green';
        ctx.fillText(`3rd Angle = ${thirdAngle}°`, textX, textY);

        return thirdAngle;
    }
    // Updated method to draw polygon
    _drawPolygon(sides) {
        const ctx = this.context;
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;
        const radius = Math.min(this.canvas.width, this.canvas.height) / 4;
        const extendLength = 60;

        ctx.beginPath();
        const vertices = [];

        // Calculate vertices and draw polygon
        for (let i = 0; i < sides; i++) {
            const angle = Math.PI / 2 + (2 * Math.PI * i / sides);
            const x = centerX + radius * Math.cos(angle);
            const y = centerY - radius * Math.sin(angle);

            vertices.push({ x, y, angle });

            if (i === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        }

        ctx.closePath();
        ctx.fillStyle = 'rgba(0, 150, 255, 0.5)';
        ctx.fill();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Draw extended sides and exterior angles
        vertices.forEach((vertex, i) => {
            const nextVertex = vertices[(i + 1) % sides];

            // Extend the sides outward
            const dx = nextVertex.x - vertex.x;
            const dy = nextVertex.y - vertex.y;
            const extendedX = nextVertex.x + dx * (extendLength / radius);
            const extendedY = nextVertex.y + dy * (extendLength / radius);

            ctx.beginPath();
            ctx.moveTo(nextVertex.x, nextVertex.y);
            ctx.lineTo(extendedX, extendedY);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.stroke();

            // Calculate the exterior angle
            const exteriorAngle = 360 / sides;

            // Display exterior angle on the left side of the extended line
            ctx.font = '16px Arial';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Calculate the label position on the left side
            const angleOffset = Math.PI; // 180 degrees to shift left
            const labelX = nextVertex.x + 40 * Math.cos(vertex.angle + angleOffset);
            const labelY = nextVertex.y - 30 * Math.sin(vertex.angle + angleOffset);

            ctx.fillText(`${exteriorAngle.toFixed(1)}°`, labelX, labelY);
        });
    }
    // Calculate the interior angle of the polygon
    _calculateInteriorAngle(n) {
        return ((n - 2) * 180) / n;
    }
    // Implement the "Draw polygon" block
    drawPolygon(args) {
        const n = parseInt(args.SIDES);
        this._initializeCanvas();
        this._clearCanvas();
        this._drawPolygon(n);
    }

    // Implement the "Calculate interior angle" block
    calculateInteriorAngle(args) {
        const n = parseInt(args.SIDES);
        if (n < 3) {
            alert('A polygon must have at least 3 sides!');
            return;
        }

        const interiorAngle = this._calculateInteriorAngle(n);

        // Display the angle on the canvas
        this._displayAngleOnCanvas(interiorAngle);
        this._drawPolygon(n);
    }
    // Display the calculated interior angle on the canvas
    _displayAngleOnCanvas(angle) {
        this._initializeCanvas();
        this._clearCanvas();
        const ctx = this.context;

        // Clear and display exterior angle below the interior angle
        ctx.font = '16px Arial';
        ctx.fillStyle = 'red';
        ctx.textAlign = 'right';
        ctx.fillText(`Interior Angle = ${angle.toFixed(2)}°`, this.canvas.width - 10, 20);
    }
    // Calculate Exterior Angle
    calculateExteriorAngle(args) {
        const sides = args.SIDES;
        this._calculateExteriorAngle(sides);
    }

    _calculateExteriorAngle(sides) {
        const ctx = this.context;
        const exteriorAngle = 360 / sides;

        // Clear and display exterior angle below the interior angle
        ctx.font = '16px Arial';
        ctx.fillStyle = 'green';
        ctx.textAlign = 'right';
        ctx.fillText(`Exterior Angle = ${exteriorAngle.toFixed(2)}°`, this.canvas.width - 10, 40);
    }
    _drawRightAngleTriangle(a, b, hypotenuse, offsetX, offsetY) {
        const ctx = this.context;

        ctx.beginPath();

        // Start point
        ctx.moveTo(offsetX, offsetY);

        // Draw the first side (base "a")
        ctx.lineTo(offsetX + a, offsetY);

        // Draw the second side (height "b")
        ctx.lineTo(offsetX, offsetY - b);

        // Draw the hypotenuse
        ctx.lineTo(offsetX, offsetY);

        // Finish the triangle
        ctx.closePath();

        // Fill the triangle
        ctx.fillStyle = 'rgba(0, 255, 150, 0.5)'; // Semi-transparent green fill
        ctx.fill();

        // Draw the outline
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.stroke();

        // // Display side lengths
        if (offsetX === this.canvas.width / 2 && offsetY === this.canvas.height / 2) {
            // Only display for the original triangle
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.fillText(`a: ${a / 30}`, offsetX + a / 2, offsetY + 20);
            ctx.fillText(`b: ${b / 30}`, offsetX - a / 3, offsetY - b / 2);
            ctx.fillText(`c: ${hypotenuse / 30}`, offsetX + a / 1.5, offsetY - b + 60);

        }
    }

    drawFourRightAngleTriangles(args) {
        const a = parseFloat(args.SIDE_A) * 30; // Triple the size to make them larger
        const b = parseFloat(args.SIDE_B) * 30; // Triple the size to make them larger
        const c = parseFloat(args.HYPOTENUSE) * 30; // Triple the size to make them larger
        // Ensure valid right-angle triangle dimensions
        if (Math.abs(c - Math.sqrt(a * a + b * b)) > 0.001) {
            console.error('Invalid right-angle triangle dimensions');
            return;
        }

        this._initializeCanvas();
        this._clearCanvas();

        // Calculate offsets for placement on the canvas
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        // Draw the first triangle (to display sides a, b, and hypotenuse c)
        this._drawRightAngleTriangle(a, b, c, centerX, centerY);

        // Draw the other three triangles at different positions
        this._drawRightAngleTriangle(a, b, c, centerX - a - 60, centerY); // Left
        this._drawRightAngleTriangle(a, b, c, centerX, centerY + b + 30); // Below
        this._drawRightAngleTriangle(a, b, c, centerX + a + 50, centerY); // Right

    }
    drawPythagoreanProof(args) {
        const a = parseFloat(args.SIDE_A) * 30;   // Scale factor for visibility
        const b = parseFloat(args.SIDE_B) * 30;
        const c = parseFloat(args.HYPOTENUSE) * 30;

        // Ensure the Pythagorean theorem holds
        if (Math.abs(c - Math.sqrt(a * a + b * b)) > 0.001) {
            console.error('Invalid right-angle triangle dimensions');
            return;
        }

        this._initializeCanvas();
        this._clearCanvas();

        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        // Starting position for the first triangle (top-left corner of the square)
        let startX = centerX - (a + b) / 2;
        let startY = centerY - (a + b) / 2;

        // Triangle 1 (Base = a, Height = b)
        this._drawRightAngleTriangle(a, -b, c, startX, startY);

        // End of Triangle 1
        let triangle1EndX = startX + a + b;
        let triangle1EndY = startY;

        // Triangle 2 (To the right of Triangle 1)
        let triangle2StartX = triangle1EndX;
        let triangle2StartY = triangle1EndY;

        // Draw Triangle 2 (Base = b, Height = a)
        this._drawRightAngleTriangle(-b, -a, c, triangle2StartX, triangle2StartY);

        // End of Triangle 2
        let triangle2EndX = triangle2StartX;
        let triangle2EndY = triangle2StartY + a + b;

        // Triangle 3 (Below Triangle 2)
        let triangle3StartX = triangle2EndX;
        let triangle3StartY = triangle2EndY;

        // Draw Triangle 3 (Base = a, Height = b)
        this._drawRightAngleTriangle(-a, b, c, triangle3StartX, triangle3StartY);

        // End of Triangle 3
        let triangle3EndX = triangle3StartX - a - b;
        let triangle3EndY = triangle3StartY;

        // Triangle 4 (To the left of Triangle 3 and below Triangle 1)
        let triangle4StartX = triangle3EndX;
        let triangle4StartY = triangle3EndY;

        // Draw Triangle 4 (Base = b, Height = a)
        this._drawRightAngleTriangle(b, a, c, triangle4StartX, triangle4StartY);

        // Now, fill the empty space formed inside the combined triangles (which forms a square with side 'c')
        const squareSide = c;  // The side length of the square (no change in size)

        // Calculate the center of the square formed inside the triangles
        const squareCenterX = triangle3EndX + a / 2 + b / 2;
        const squareCenterY = triangle2EndY - a / 2 - b / 2;

        // Set light purple color fill for the inner square
        this.context.fillStyle = 'rgb(200, 150, 255)'; // Light purple color

        // Save current canvas context state
        this.context.save();

        // Move the canvas origin to the center of the square
        this.context.translate(squareCenterX, squareCenterY);

        // Rotate the square by 40 degrees (in radians)
        this.context.rotate(36.87 * Math.PI / 180);  // Convert 40 degrees to radians

        // Draw the rotated square
        this.context.fillRect(-squareSide / 2, -squareSide / 2, squareSide, squareSide);

        // Restore the canvas state
        this.context.restore();

        // Add text to the center of the inner square
        const text = `Area = c² = ${c / 30}² = ${(c / 30) * (c / 30)}`;
        const textX = squareCenterX;
        const textY = squareCenterY;

        // Set text styles
        this.context.font = '20px Arial';
        this.context.fillStyle = 'yellow';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';

        // Draw the text in the middle of the square
        this.context.fillText(text, textX, textY);
    }
    drawRectanglesWithTriangles(args) {
        const a = parseFloat(args.SIDE_A) * 30;   // Scale factor for visibility
        const b = parseFloat(args.SIDE_B) * 30;
        const c = parseFloat(args.HYPOTENUSE) * 30;

        // Ensure the Pythagorean theorem holds
        if (Math.abs(c - Math.sqrt(a * a + b * b)) > 0.001) {
            console.error('Invalid right-angle triangle dimensions');
            return;
        }

        this._initializeCanvas();
        this._clearCanvas();

        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        // Positioning Triangle 1 (Top-left of the first rectangle)
        let startX = centerX - (a + b) / 2;
        let startY = centerY - (a + b) / 2;

        // Draw Triangle 1 (Base = a, Height = b)
        this._drawRightAngleTriangle(a, -b, c, startX, startY);

        // End of Triangle 1 (Right corner)
        let triangle1EndX = startX + a;
        let triangle1EndY = startY + b;

        // Position Triangle 2 (Positioned to the right of Triangle 1)
        let triangle2StartX = triangle1EndX;
        let triangle2StartY = triangle1EndY;

        // Draw Triangle 2 (Base = b, Height = a)
        this._drawRightAngleTriangle(-a, b, c, triangle2StartX, triangle2StartY);

        // End of Triangle 2 (Bottom right corner of the rectangle)
        let triangle2EndX = triangle2StartX + b;
        let triangle2EndY = triangle2StartY;

        // Positioning Triangle 3 (for second rectangle)
        let triangle3StartX = triangle2EndX;
        let triangle3StartY = triangle2EndY;

        // Draw Triangle 3 (Base = a, Height = b)
        this._drawRightAngleTriangle(-b, -a, c, triangle3StartX, triangle3StartY);

        // End of Triangle 3 (Left corner of the second rectangle)
        let triangle3EndX = triangle3StartX - b;
        let triangle3EndY = triangle3StartY + a;

        // Position Triangle 4 (Completing the second rectangle)
        let triangle4StartX = triangle3EndX;
        let triangle4StartY = triangle3EndY;

        // Draw Triangle 4 (Base = b, Height = a)
        this._drawRightAngleTriangle(b, a, c, triangle4StartX, triangle4StartY);

        // Draw Squares
        this._drawSquare(startX, startY + b, a, 'a² = ' + (a * a / 900).toFixed(2));
        this._drawSquare(triangle2StartX, triangle2StartY - b, b, 'b² = ' + (b * b / 900).toFixed(2));
    }

    _drawSquare(x, y, side, label) {
        const ctx = this.context;
        ctx.beginPath();
        ctx.rect(x, y, side, side);
        ctx.fillStyle = 'rgb(200, 150, 255)'; // Light purple with 30% opacity
        ctx.fill();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();

        // Draw Label
        ctx.font = '16px Arial';
        ctx.fillStyle = 'yellow';
        ctx.fillText(label, x + side / 2, y + side / 2);
    }
    // Block 4: Display final proof
    displayProof(args) {
        const a = parseFloat(args.SIDE_A) * 30;   // Scale factor for visibility
        const b = parseFloat(args.SIDE_B) * 30;
        const c = parseFloat(args.HYPOTENUSE) * 30;

        // Ensure the Pythagorean theorem holds
        if (Math.abs(c - Math.sqrt(a * a + b * b)) > 0.001) {
            console.error('Invalid right-angle triangle dimensions');
            return;
        }

        this._initializeCanvas();
        this._clearCanvas();

        const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height;

        // Position calculations to fit all three squares on the canvas
        const padding = 20;
        const spaceBetweenSquares = 40; // Space between squares c and a
        const additionSymbolSpace = 20; // Space for addition symbol (+) between a and b
        const centerY = canvasHeight / 2;

        // Position the large square (side c) on the left side of the canvas
        const largeSquareX = padding;
        const largeSquareY = centerY - c / 2;

        // Position the smaller square (side a) to the right of the large square, with some space
        const smallSquareAX = largeSquareX + c + spaceBetweenSquares;
        const smallSquareAY = largeSquareY + (c - a) / 2;

        // Position the equal sign (=) between square c and square a
        const equalSignX = largeSquareX + c + spaceBetweenSquares / 2; // Centered between c and a
        const equalSignY = centerY; // Vertically centered with the squares

        // Position the smaller square (side b) further to the right
        const smallSquareBX = smallSquareAX + a + additionSymbolSpace + 30; // Further right for square b
        const smallSquareBY = largeSquareY + (c - b) / 2;

        // Draw the large square (side c)
        this._drawSquare(largeSquareX, largeSquareY, c, `c² = ${(c / 30) ** 2}`);

        // Draw the smaller square (side a)
        this._drawSquare(smallSquareAX, smallSquareAY, a, `a² = ${(a / 30) ** 2}`);

        // Draw the equal sign (=) between square c and square a
        this.context.font = '30px Arial';
        this.context.fillStyle = 'black';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText('=', equalSignX, equalSignY);

        // Draw the addition symbol (+) between squares a and b
        const additionSymbolX = smallSquareAX + a + additionSymbolSpace;
        const additionSymbolY = centerY;
        this.context.fillText('+', additionSymbolX, additionSymbolY);

        // Draw the smaller square (side b)
        this._drawSquare(smallSquareBX, smallSquareBY, b, `b² = ${(b / 30) ** 2}`);

        // Add a visual representation of the equation: a² + b² = c²
        const textX = canvasWidth / 2; // Centered horizontally
        const textY = largeSquareY - padding * 2; // Positioned above the large square

        this.context.font = '30px Arial';
        this.context.fillStyle = 'red';
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        this.context.fillText(`a² + b² = c²`, textX, textY);
    }
    generateTriangle(args) {
        const a = parseFloat(args.SIDE_A);
        const b = parseFloat(args.SIDE_B);
    
        // Initialize and clear canvas before drawing
        this._initializeCanvas();
        this._clearCanvas();
    
        if (a > 0 && b > 0) {
            this._drawRightTriangle(a, b);
        } else {
            console.error("Both sides must be positive numbers.");
        }
    }   
    // Draw Right-Angled Triangle
    _drawRightTriangle(a, b) {
        const ctx = this.context;
        const scale = 30;
    
        // Coordinates
        const startX = this.canvas.width / 2 - a * scale / 2;
        const startY = this.canvas.height / 2 + b * scale / 2;
    
        // Calculate Hypotenuse
        const c = Math.sqrt(a * a + b * b);
    
        // Draw Triangle
        ctx.beginPath();
        ctx.moveTo(startX, startY);               // Bottom-left
        ctx.lineTo(startX + a * scale, startY);  // Bottom-right
        ctx.lineTo(startX, startY - b * scale);  // Top-left
        ctx.closePath();
    
        ctx.fillStyle = 'rgba(100, 200, 255, 0.5)';
        ctx.fill();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.stroke();
    
        // Labels
        ctx.font = '18px Arial';
        ctx.fillStyle = 'red';
        ctx.textAlign = 'center';
    
        // Add Side Labels
        ctx.fillText(`a = ${a}`, startX + a * scale / 2, startY + 20);
        ctx.fillText(`b = ${b}`, startX - 20, startY - b * scale / 2);
        // ctx.fillText(`c = ${c.toFixed(2)}`, startX + a * scale / 2, startY - b * scale / 2 - 20);
    }   
   // Calculate the hypotenuse and display it on the canvas
calculateHypotenuse(args) {
    const a = parseFloat(args.SIDE_A);
    const b = parseFloat(args.SIDE_B);

    // Initialize and clear canvas
    this._initializeCanvas();
    this._clearCanvas();

    if (a > 0 && b > 0) {
        const c = Math.sqrt(a * a + b * b).toFixed(2);

        // Draw the triangle
        this._drawRightTriangle(a, b);

        const ctx = this.context;
        const scale = 30;

        // Display hypotenuse value at the top-right of the canvas
        ctx.font = '20px Arial';
        ctx.fillStyle = 'purple';
        ctx.textAlign = 'right';
        ctx.fillText(`Hypotenuse: ${c}`, this.canvas.width - 10, 20);

        // Coordinates for side label
        const startX = this.canvas.width / 2 +a/3 * scale / 2;
        const startY = this.canvas.height / 2 + b * scale / 2;

        // Label the hypotenuse on the triangle side
        ctx.textAlign = 'center';
        ctx.fillText(
            `c = ${c}`, 
            startX + a * scale / 2, 
            startY - b * scale / 2 - 20
        );
    } else {
        console.error("Both sides must be positive numbers.");
    }
}
}
module.exports = AngleRepresentation;
