const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const Cast = require('../../util/cast');

class Fractions {
    constructor(runtime) {
        this.runtime = runtime;
        this.canvas = null; // To hold the dynamic canvas
        this.context = null; // To hold the canvas rendering context
        this.currentPoint = undefined; // To track the current point for animations

    }

    getInfo() {
        return {
            id: 'simpleMath',
            name: 'Fractions',
            color1: '#F67280',
            blocks: [
               
                {
                    opcode: 'visualizeFraction',
                    blockType: BlockType.COMMAND,
                    text: 'Show fraction [NUMERATOR]/[DENOMINATOR]',
                    arguments: {
                        NUMERATOR: { type: ArgumentType.NUMBER, defaultValue: 3 },
                        DENOMINATOR: { type: ArgumentType.NUMBER, defaultValue: 5 }
                    }
                },              
                {
                    opcode: 'compareFractions',
                    blockType: BlockType.COMMAND,
                    text: 'Compare fractions [FRACTION1] and [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '1/2' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '2/3' }
                    }
                },
                {
                    opcode: 'addFractions',
                    blockType: BlockType.COMMAND,
                    text: 'Add fractions [FRACTION1] + [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '1/4' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '1/2' }
                    }
                },
                {
                    opcode: 'subtractFractions',
                    blockType: BlockType.COMMAND,
                    text: 'Subtract fractions [FRACTION1] and [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '3/4' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '1/4' }
                    }
                },
                {
                    opcode: 'multiplyFractions',
                    blockType: BlockType.COMMAND,
                    text: 'Multiply fractions [FRACTION1] and [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '2/3' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '3/4' }
                    }
                },
                {
                    opcode: 'divideFractions',
                    blockType: BlockType.COMMAND,
                    text: 'Divide fractions [FRACTION1] by [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '2/3' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '4/5' }
                    }
                },
                
                {
                    opcode: 'simplifyFraction',
                    blockType: BlockType.REPORTER, // REPORTER to return the simplified fraction
                    text: 'Simplify fraction [FRACTION]',
                    arguments: {
                        FRACTION: { type: ArgumentType.STRING, defaultValue: '8/12' }
                    }
                },
                {
                    opcode: 'convertFractionToDecimal',
                    blockType: BlockType.REPORTER,
                    text: 'Convert Fraction [NUMERATOR] / [DENOMINATOR] to Decimal',
                    arguments: {
                        NUMERATOR: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        },
                        DENOMINATOR: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 2
                        }
                    }
                },
                {
                    opcode: 'mixedFraction',
                    blockType: BlockType.COMMAND, // REPORTER to return the mixed fraction
                    text: 'Convert [FRACTION] to mixed fraction',
                    arguments: {
                        FRACTION: { type: ArgumentType.STRING, defaultValue: '7/3' }
                    }
                },
               
                {
                    opcode: 'addFraction',
                    blockType: BlockType.COMMAND,
                    text: 'Add explanation [FRACTION1] and [FRACTION2]',
                    arguments: {
                        FRACTION1: { type: ArgumentType.STRING, defaultValue: '1/2' },
                        FRACTION2: { type: ArgumentType.STRING, defaultValue: '1/3' }
                    }
                },
                {
                    opcode: 'convertFractionToPercentage',
                    blockType: BlockType.COMMAND,
                    text: 'Convert fraction [FRACTION] to percentage',
                    arguments: {
                        FRACTION: { type: ArgumentType.STRING, defaultValue: '1/2' }
                    }
                },
                {
                    opcode: 'clearCanvas',
                    blockType: BlockType.COMMAND,
                    text: 'Clear Canvas',
                    func: 'clearCanvas'
                },
                
                

            ]
        };
    }
    
   
  visualizeFraction(args) {
    const numerator = Cast.toNumber(args.NUMERATOR);
    const denominator = Cast.toNumber(args.DENOMINATOR);

    if (isNaN(numerator) || isNaN(denominator) || denominator <= 0 || numerator > denominator) {
        console.error("Invalid input: Ensure numerator <= denominator and denominator > 0.");
        return;
    }

    // Hide all sprites on the Scratch stage
    const targets = this.runtime.targets;
    targets.forEach(target => {
        if (!target.isStage) {
            target.setVisible(false);
        }
    });

    // Remove existing canvas if any
    if (this.canvas) {
        this.canvas.remove();
    }

    // Get the Scratch stage container
    const stageContainer = document.querySelector('.scratch-stage') || document.body;

    // Create a new canvas
    this.canvas = document.createElement('canvas');
    this.canvas.width = 480; // Match Scratch stage width
    this.canvas.height = 360; // Match Scratch stage height
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px'; // Adjust to align on the stage
    this.canvas.style.right = '10px'; // Adjust to align on the stage
    this.canvas.style.left = 'auto'
    this.canvas.style.pointerEvents = 'none'; // Allow interactions with Scratch UI
    this.canvas.style.zIndex = '10'; // Ensure it overlays above the stage
    this.canvas.style.border = '1px solid black';

    stageContainer.appendChild(this.canvas);
    this.context = this.canvas.getContext('2d');

    const context = this.context;
    const centerX = this.canvas.width / 2; // Center of the canvas
    const centerY = this.canvas.height / 2; // Center of the canvas
    const radius = 120; // Radius for the circle

    // Clear the canvas before drawing
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Calculate the angle for each segment
    const segmentAngle = (2 * Math.PI) / denominator;

    // Draw the segments
    for (let i = 0; i < denominator; i++) {
        context.beginPath();
        context.moveTo(centerX, centerY);
        context.arc(
            centerX,
            centerY,
            radius,
            i * segmentAngle,
            (i + 1) * segmentAngle
        );
        context.closePath();

        // Fill the segment if it is part of the numerator
        if (i < numerator) {
            context.fillStyle = 'lightblue'; // Filled segment color
        } else {
            context.fillStyle = 'lightgrey'; // Empty segment color
        }
        context.fill();

        // Draw segment borders
        context.strokeStyle = 'black';
        context.lineWidth = 1;
        context.stroke();
    }

    // Draw the circle outline
    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    context.stroke();

    // Add the fraction label in the center of the circle
    context.font = '24px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(`${numerator}/${denominator}`, centerX, centerY+radius+20);
 }


  
  compareFractions(args) {
    const [num1, den1] = args.FRACTION1.split('/').map(Number);
    const [num2, den2] = args.FRACTION2.split('/').map(Number);

    if (!den1 || !den2 || den1 <= 0 || den2 <= 0) {
        console.error('Invalid input: Ensure valid fractions with positive denominators.');
        return;
    }

    // Remove existing canvas if any
    if (this.canvas) {
        this.canvas.remove();
    }

    // Get the Scratch stage container
    const stageContainer = document.querySelector('.scratch-stage') || document.body;

    // Create a new canvas
    this.canvas = document.createElement('canvas');
    this.canvas.width = 480; // Match Scratch stage width
    this.canvas.height = 360; // Match Scratch stage height
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px';
    this.canvas.style.right = '10px';
    this.canvas.style.left = 'auto';
    this.canvas.style.pointerEvents = 'none'; // Allow interactions with Scratch UI
    this.canvas.style.zIndex = '10'; // Ensure it overlays above the stage
    this.canvas.style.border = '1px solid black';

    stageContainer.appendChild(this.canvas);
    this.context = this.canvas.getContext('2d');

    const context = this.context;
    const centerY = this.canvas.height / 2; // Center Y position for circles
    const radius = 100; // Radius for the circles

    // Circle 1 - Fraction 1
    const centerX1 = this.canvas.width / 4; // Center X for the first circle
    this.drawFractionCircle(context, centerX1, centerY, radius, num1, den1, 'lightblue');

    // Circle 2 - Fraction 2
    const centerX2 = (this.canvas.width * 3) / 4; // Center X for the second circle
    this.drawFractionCircle(context, centerX2, centerY, radius, num2, den2, 'lightgreen');

    // Add labels
    context.font = '18px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'center';
    context.fillText(`${num1}/${den1}`, centerX1, centerY + radius + 30);
    context.fillText(`${num2}/${den2}`, centerX2, centerY + radius + 30);

    // Determine which fraction is larger
    const value1 = num1 / den1;
    const value2 = num2 / den2;

    if (value1 > value2) {
        this.highlightCircle(context, centerX1, centerY, radius, 'red');
        context.fillStyle = 'red'; // Set text color
        context.font = '16px Arial'; // Set font size and style
        context.fillText('Circle 1 is greater than Circle 2', (centerX1 + centerX2) / 2 - 50, centerY - radius - 10); // Position the text
    } else if (value2 > value1) {
        this.highlightCircle(context, centerX2, centerY, radius, 'red');
        context.fillStyle = 'red'; // Set text color
        context.font = '16px Arial'; // Set font size and style
        context.fillText('Circle 2 is greater than Circle 1',(centerX1 + centerX2) / 2 - 50, centerY - radius - 10); // Position the text
    } else {
        context.fillStyle = 'black'; // Set text color
        context.font = '16px Arial'; // Set font size and style
        context.fillText('Circle 1 is equal to Circle 2', (centerX1 + centerX2) / 2 - 50, centerY - radius - 10); // Position the text
    }
    

    
 }

   // Helper function to draw a fraction circle
   drawFractionCircle(context, centerX, centerY, radius, numerator, denominator, color) {
    const segmentAngle = (2 * Math.PI) / denominator;

    for (let i = 0; i < denominator; i++) {
        context.beginPath();
        context.moveTo(centerX, centerY);
        context.arc(
            centerX,
            centerY,
            radius,
            i * segmentAngle,
            (i + 1) * segmentAngle
        );
        context.closePath();

        // Fill the segment if it's part of the numerator
        if (i < numerator) {
            context.fillStyle = color;
        } else {
            context.fillStyle = 'lightgrey';
        }
        context.fill();

        // Draw segment borders
        context.strokeStyle = 'black';
        context.lineWidth = 1;
        context.stroke();
    }

    // Draw the circle outline
    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    context.stroke();
   }

   // Helper function to highlight a circle
     highlightCircle(context, centerX, centerY, radius, color) {
    context.beginPath();
    context.arc(centerX, centerY, radius + 5, 0, 2 * Math.PI);
    context.strokeStyle = color;
    context.lineWidth = 3;
    context.stroke();
   }
   
   // Add Fractions
   addFractions(args) {
    const [num1, den1] = args.FRACTION1.split('/').map(Number);
    const [num2, den2] = args.FRACTION2.split('/').map(Number);

    if (!den1 || !den2 || den1 <= 0 || den2 <= 0) {
        console.error('Invalid input: Ensure valid fractions with positive denominators.');
        return;
    }

    // Find the common denominator
    const commonDenominator = den1 * den2;
    const adjustedNum1 = num1 * den2;
    const adjustedNum2 = num2 * den1;

    // Calculate the resulting fraction
    const resultNumerator = adjustedNum1 + adjustedNum2;
    const resultFraction = `${resultNumerator}/${commonDenominator}`;

    this.visualizeFractions(num1, den1, num2, den2, resultNumerator, commonDenominator, "lightblue", "lightgreen", "Addition");
  }

   // Subtract Fractions
   subtractFractions(args) {
    const [num1, den1] = args.FRACTION1.split('/').map(Number);
    const [num2, den2] = args.FRACTION2.split('/').map(Number);

    if (!den1 || !den2 || den1 <= 0 || den2 <= 0 || num1 * den2 < num2 * den1) {
        console.error('Invalid input: Ensure valid fractions with positive denominators and valid subtraction.');
        return;
    }

    // Find the common denominator
    const commonDenominator = den1 * den2;
    const adjustedNum1 = num1 * den2;
    const adjustedNum2 = num2 * den1;

    // Calculate the resulting fraction
    const resultNumerator = adjustedNum1 - adjustedNum2;
    const resultFraction = `${resultNumerator}/${commonDenominator}`;

    this.visualizeFractions(num1, den1, num2, den2, resultNumerator, commonDenominator, "lightcoral", "orange", "Subtraction");
   }

   // Multiply Fractions
   multiplyFractions(args) {
    const [num1, den1] = args.FRACTION1.split('/').map(Number);
    const [num2, den2] = args.FRACTION2.split('/').map(Number);

    if (!den1 || !den2 || den1 <= 0 || den2 <= 0) {
        console.error('Invalid input: Ensure valid fractions with positive denominators.');
        return;
    }

    // Multiply numerators and denominators
    const resultNumerator = num1 * num2;
    const resultDenominator = den1 * den2;
    const resultFraction = `${resultNumerator}/${resultDenominator}`;

    this.visualizeFractions(num1, den1, num2, den2, resultNumerator, resultDenominator, "blue", "green", "Multiplication");
   }

   // Divide Fractions
   divideFractions(args) {
    const [num1, den1] = args.FRACTION1.split('/').map(Number);
    const [num2, den2] = args.FRACTION2.split('/').map(Number);

    if (!den1 || !den2 || num2 === 0 || den2 === 0) {
        console.error('Invalid input: Ensure valid fractions and non-zero divisor.');
        return;
    }

    // Invert the second fraction and multiply
    const resultNumerator = num1 * den2;
    const resultDenominator = den1 * num2;
    const resultFraction = `${resultNumerator}/${resultDenominator}`;

    this.visualizeFractions(num1, den1, num2, den2, resultNumerator, resultDenominator, "purple", "orange", "Division");
   }

   // Helper function to visualize fractions
    visualizeFractions(num1, den1, num2, den2, resultNum, resultDen, color1, color2, operation) {
    // Remove existing canvas
    if (this.canvas) {
        this.canvas.remove();
    }

    // Set up a new canvas
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    this.canvas.width = 700;
    this.canvas.height = 350;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px';
    this.canvas.style.right = '10px';
    this.canvas.style.left = 'auto';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';

    stageContainer.appendChild(this.canvas);
    const context = this.canvas.getContext('2d');
    const centerY = this.canvas.height / 2 - 50;
    const radius = 80;

    // Define spacing for circles
    const circleSpacing = 200;
    const centerX1 = this.canvas.width / 4 - circleSpacing / 3;
    const centerX2 = this.canvas.width / 2;
    const centerX3 = this.canvas.width * 3 / 4 + circleSpacing / 3;

    // Draw fraction circles
    this.drawFractionCircle(context, centerX1, centerY, radius, num1, den1, color1);
    this.drawFractionCircle(context, centerX2, centerY, radius, num2, den2, color2);
    this.drawFractionCircle(context, centerX3, centerY, radius, resultNum, resultDen, 'yellow');

    // Add labels
    context.font = '16px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'center';
    context.fillText(`${num1}/${den1}`, centerX1, centerY + radius + 30);
    context.fillText(`${num2}/${den2}`, centerX2, centerY + radius + 30);
    context.fillText(`${resultNum}/${resultDen}`, centerX3, centerY + radius + 30);
    context.fillText(operation, centerX3, centerY + radius + 50);

    console.log(`Resulting fraction for ${operation}: ${resultNum}/${resultDen}`);
   }

   // Helper function to draw a fraction circle
   drawFractionCircle(context, centerX, centerY, radius, numerator, denominator, color) {
    const segmentAngle = (2 * Math.PI) / denominator;

    for (let i = 0; i < denominator; i++) {
        context.beginPath();
        context.moveTo(centerX, centerY);
        context.arc(
            centerX,
            centerY,
            radius,
            i * segmentAngle,
            (i + 1) * segmentAngle
        );
        context.closePath();

        // Fill segment if part of numerator
        context.fillStyle = i < numerator ? color : 'lightgrey';
        context.fill();
        context.strokeStyle = 'black';
        context.lineWidth = 1;
        context.stroke();
    }

    // Draw circle outline
    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    context.stroke();
   }

  

 simplifyFraction(args) {
    const fraction = args.FRACTION;

    // Parse the input fraction (e.g., "8/12")
    const [numeratorStr, denominatorStr] = fraction.split('/');
    const numerator = parseInt(numeratorStr, 10);
    const denominator = parseInt(denominatorStr, 10);

    // Validate the input
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
        console.error("Invalid fraction input. Please provide a valid fraction in the format 'numerator/denominator'.");
        return 'Invalid input';
    }

    // Function to calculate the GCD
    const gcd = (a, b) => {
        while (b !== 0) {
            const temp = b;
            b = a % b;
            a = temp;
        }
        return Math.abs(a);
    };

    // Simplify the fraction
    const divisor = gcd(numerator, denominator);
    const simplifiedNumerator = numerator / divisor;
    const simplifiedDenominator = denominator / divisor;

    // Return the simplified fraction as a string
    return `${simplifiedNumerator}/${simplifiedDenominator}`;
 }
 convertFractionToDecimal(args) {
    const numerator = Number(args.NUMERATOR);
    const denominator = Number(args.DENOMINATOR);

    if (!denominator || denominator <= 0) {
        console.error('Invalid input: Denominator must be positive.');
        return;
    }

    const decimalValue = numerator / denominator;

    // Visualization
    this.visualizeFractionToDecimal(numerator, denominator, decimalValue);
  }

  visualizeFractionToDecimal(numerator, denominator, decimalValue) {
    // First, clear the canvas if it exists
    this.clearCanvas();

    // Create a new canvas
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    this.canvas.width = 480;
    this.canvas.height = 360;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px';
    this.canvas.style.right = '10px';
    this.canvas.style.left = 'auto';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';

    stageContainer.appendChild(this.canvas);

    // Get the canvas context for drawing
    const context = this.canvas.getContext('2d');
    const centerY = this.canvas.height / 2 - 50;
    const radius = 80;

    // Draw the fraction circle
    this.drawFractionCircle(context, this.canvas.width / 2 - 100, centerY, radius, numerator, denominator, 'orange');

    // Add the decimal value label
    context.font = '16px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'center';
    context.fillText(`${numerator}/${denominator} = ${decimalValue.toFixed(2)}`, this.canvas.width / 2 + 100, centerY + radius + 30);
  }

  mixedFraction(args) {
    const fraction = args.FRACTION;

    // Parse the input fraction (e.g., "7/3")
    const [numeratorStr, denominatorStr] = fraction.split('/');
    const numerator = parseInt(numeratorStr, 10);
    const denominator = parseInt(denominatorStr, 10);

    // Validate the input
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
        console.error("Invalid fraction input. Please provide a valid fraction in the format 'numerator/denominator'.");
        return 'Invalid input';
    }

    // Convert to mixed fraction: whole part and remainder
    const wholePart = Math.floor(numerator / denominator);
    const remainder = numerator % denominator;

    // Display the mixed fraction as a string (e.g., "2 1/3")
    const mixedFractionString = `${wholePart} ${remainder}/${denominator}`;

    // Visualize the mixed fraction
    this.visualizeMixedFraction(wholePart, remainder, denominator);

    // Return the mixed fraction as a string
    return mixedFractionString;
    }

   // Method to visualize the mixed fraction (whole number + fractional part)
   visualizeMixedFraction(wholePart, remainder, denominator) {
    // Create canvas if it doesn't exist
    if (!this.canvas) {
        const stageContainer = document.querySelector('.scratch-stage') || document.body;
        this.canvas = document.createElement('canvas');
        this.canvas.width = 480;
        this.canvas.height = 360;
        this.canvas.style.position = 'absolute';
        this.canvas.style.top = '90px';
        this.canvas.style.right = '10px';
        this.canvas.style.left = 'auto';
        this.canvas.style.pointerEvents = 'none';
        this.canvas.style.zIndex = '10';
        this.canvas.style.border = '1px solid black';
        stageContainer.appendChild(this.canvas); 
    }

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height); // Clear previous visualizations

    // Define positions and sizes
    const startX = 50;  // Starting X position for the bars
    const startY = 80;  // Starting Y position for the bars
    const barWidth = 300;  // Total width of the bar
    const barHeight = 40;  // Height of the bar
    const segmentWidth = barWidth / denominator;  // Width of each segment

    // Draw the bar segments for the whole part
    context.fillStyle = 'blue';
    for (let i = 0; i < wholePart; i++) {
        context.fillRect(startX + i * segmentWidth, startY, segmentWidth, barHeight);
    }

    // Draw the fractional part as a green segment
    context.fillStyle = 'pink';
    if (remainder > 0) {
        context.fillRect(startX + wholePart * segmentWidth, startY, segmentWidth * (remainder / denominator), barHeight);
    }

    // Draw division lines and labels for each segment
    context.strokeStyle = 'black';
    context.lineWidth = 1;
    for (let i = 0; i <= denominator; i++) {
        const x = startX + i * segmentWidth;
        context.beginPath();
        context.moveTo(x, startY);
        context.lineTo(x, startY + barHeight);
        context.stroke();

        // Add labels for the segments
        if (i < denominator) {
            context.font = '12px Arial';
            context.fillStyle = 'black';
            const label = i < wholePart
                ? `${i + 1}`  // Label whole parts with numbers
                : `${i === wholePart ? remainder + '/' + denominator : ''}`;
            context.fillText(label, x + segmentWidth / 2, startY + barHeight + 15);
        }
    }

    // Add a title to describe the mixed fraction
    context.font = '16px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'center';
    context.fillText(`Mixed Fraction: ${wholePart} ${remainder}/${denominator}`, this.canvas.width / 2, startY - 20);
  }


  clearCanvas() {
    if (this.canvas) {
        this.canvas.remove();  // Remove the canvas element from the DOM
        this.canvas = null;    // Reset the canvas reference to null
    } else {
        console.log('Canvas is not initialized. Nothing to clear.');
    }

   }


   
  

 
  makesimplFractionVisual(args) {
    const fraction = args.FRACTION;

    // Parse the input fraction (e.g., "8/12")
    const [numeratorStr, denominatorStr] = fraction.split('/');
    const numerator = parseInt(numeratorStr, 10);
    const denominator = parseInt(denominatorStr, 10);

    // Validate input
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
        console.error("Invalid fraction input. Please provide a valid fraction in the format 'numerator/denominator'.");
        this.displayCanvasMessage('Invalid input. Please use the format "numerator/denominator".');
        return;
    }

    // Ensure numerator and denominator are positive
    const absNumerator = Math.abs(numerator);
    const absDenominator = Math.abs(denominator);

    // Function to calculate GCD
    const gcd = (a, b) => {
        while (b !== 0) {
            [a, b] = [b, a % b];
        }
        return a;
    };

    // Calculate GCD
    const divisor = gcd(absNumerator, absDenominator);

    // Simplify the fraction
    const simplifiedNumerator = absNumerator / divisor;
    const simplifiedDenominator = absDenominator / divisor;

    // Visualize the steps on canvas
    this.visualizeSimplification(
        absNumerator,
        absDenominator,
        divisor,
        simplifiedNumerator,
        simplifiedDenominator
    );

    // Return the simplified fraction
    return `${simplifiedNumerator}/${simplifiedDenominator}`;
  }

   visualizeSimplification(numerator, denominator, gcd, simplifiedNumerator, simplifiedDenominator) {
    // Initialize canvas
    if (this.canvas) this.canvas.remove();
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    
    this.canvas.width = 480;
    this.canvas.height = 360;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px';
    this.canvas.style.right = '10px';
    this.canvas.style.left = 'auto';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';
    stageContainer.appendChild(this.canvas);

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Display steps
    context.font = '16px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'left';

    // Step 1: Original fraction
    context.fillText(`Step 1: Start with the fraction ${numerator}/${denominator}`, 10, 40);

    // Step 2: Find GCD
    context.fillText(`Step 2: Find the GCD of ${numerator} and ${denominator} = ${gcd}`, 10, 80);

    // Step 3: Divide numerator and denominator by GCD
    context.fillText(`Step 3: Simplify by dividing both by GCD (${gcd})`, 10, 120);
    context.fillText(`   Numerator: ${numerator} ÷ ${gcd} = ${simplifiedNumerator}`, 10, 160);
    context.fillText(`   Denominator: ${denominator} ÷ ${gcd} = ${simplifiedDenominator}`, 10, 200);

    // Step 4: Display simplified fraction
    context.fillText(`Result: ${numerator}/${denominator} simplifies to ${simplifiedNumerator}/${simplifiedDenominator}`, 10, 240);
   }

   displayCanvasMessage(message) {
    if (this.canvas) this.canvas.remove();
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    this.canvas.width = 480;
    this.canvas.height = 360;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '90px';
    this.canvas.style.right = '10px';
    this.canvas.style.left = 'auto';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';
    stageContainer.appendChild(this.canvas);

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    context.font = '16px Arial';
    context.fillStyle = 'red';
    context.textAlign = 'center';
    context.fillText(message, this.canvas.width / 2, this.canvas.height / 2);
  }
  addFraction(args) {
    const fraction1 = args.FRACTION1;
    const fraction2 = args.FRACTION2;

    // Parse the input fractions
    const [numerator1, denominator1] = fraction1.split('/').map(Number);
    const [numerator2, denominator2] = fraction2.split('/').map(Number);

    // Validate input
    if (
        isNaN(numerator1) || isNaN(denominator1) || denominator1 === 0 ||
        isNaN(numerator2) || isNaN(denominator2) || denominator2 === 0
    ) {
        console.error("Invalid input. Provide fractions in 'numerator/denominator' format.");
        this.displayCanvasMessage('Invalid input. Please use "numerator/denominator" format.');
        return;
    }

    // Find the Least Common Denominator (LCD)
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const lcm = (a, b) => (a * b) / gcd(a, b);
    const lcd = lcm(denominator1, denominator2);

    // Convert fractions to have the same denominator
    const newNumerator1 = numerator1 * (lcd / denominator1);
    const newNumerator2 = numerator2 * (lcd / denominator2);

    // Add the numerators
    const sumNumerator = newNumerator1 + newNumerator2;

    // Simplify the resulting fraction
    const divisor = gcd(sumNumerator, lcd);
    const simplifiedNumerator = sumNumerator / divisor;
    const simplifiedDenominator = lcd / divisor;

    // Visualize the steps
    this.visualizeAdditionSteps(
        numerator1, denominator1,
        numerator2, denominator2,
        lcd, newNumerator1, newNumerator2,
        sumNumerator, simplifiedNumerator, simplifiedDenominator
    );

    // Return the result
    return `${simplifiedNumerator}/${simplifiedDenominator}`;
  }

  visualizeAdditionSteps(
    numerator1, denominator1, numerator2, denominator2,
    lcd, newNumerator1, newNumerator2, sumNumerator, simplifiedNumerator, simplifiedDenominator
  ) {
    // Initialize canvas
    if (this.canvas) this.canvas.remove();
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    this.canvas.width = 480;
    this.canvas.height = 360;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '100px';
    this.canvas.style.right = '10px'; // Position canvas on the right
    this.canvas.style.left = 'auto'; // Disable the left positioning
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';
    stageContainer.appendChild(this.canvas);

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Step-by-step visualization
    context.font = '16px Arial';
    context.fillStyle = 'black';
    context.textAlign = 'left';
    context.fillText(`Step 1: Original fractions: ${numerator1}/${denominator1} and ${numerator2}/${denominator2}`, 10, 40);
    context.fillText(`Step 2: Find the Least Common Denominator (LCD) = ${lcd}`, 10, 80);
    context.fillText(`Step 3: Convert fractions to have LCD`, 10, 120);
    context.fillText(`   First fraction: ${numerator1}/${denominator1} → ${newNumerator1}/${lcd}`, 10, 160);
    context.fillText(`   Second fraction: ${numerator2}/${denominator2} → ${newNumerator2}/${lcd}`, 10, 200);
    context.fillText(`Step 4: Add the numerators: ${newNumerator1} + ${newNumerator2} = ${sumNumerator}`, 10, 240);
    context.fillText(`Step 5: Simplify: ${sumNumerator}/${lcd} → ${simplifiedNumerator}/${simplifiedDenominator}`, 10, 280);
    context.fillText(`Result: ${simplifiedNumerator}/${simplifiedDenominator}`, 10, 320);
  }


  displayCanvasMessage(message) {
    if (this.canvas) this.canvas.remove();
    const stageContainer = document.querySelector('.scratch-stage') || document.body;
    this.canvas = document.createElement('canvas');
    this.canvas.width = 600;
    this.canvas.height = 400;
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '100px';
    this.canvas.style.left = '50px';
    this.canvas.style.zIndex = '10';
    this.canvas.style.border = '1px solid black';
    stageContainer.appendChild(this.canvas);

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    context.font = '16px Arial';
    context.fillStyle = 'red';
    context.textAlign = 'center';
    context.fillText(message, this.canvas.width / 2, this.canvas.height / 2);
 }
 convertFractionToPercentage(args) {
    const fraction = args.FRACTION;

    // Parse the input fraction (e.g., "3/4")
    const [numeratorStr, denominatorStr] = fraction.split('/');
    const numerator = parseInt(numeratorStr, 10);
    const denominator = parseInt(denominatorStr, 10);

    // Validate the input
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
        console.error("Invalid fraction input. Please provide a valid fraction in the format 'numerator/denominator'.");
        return 'Invalid input';
    }

    // Calculate the percentage
    const percentage = (numerator / denominator) * 100;

    // Visualize the percentage
    this.visualizeFractionToPercentage(numerator, denominator, percentage);

    // Return the percentage as a string
    return `${percentage.toFixed(2)}%`;
}

   // Method to visualize the percentage
   visualizeFractionToPercentage(numerator, denominator, percentage) {
    // Create canvas if it doesn't exist
    if (!this.canvas) {
        const stageContainer = document.querySelector('.scratch-stage') || document.body;
        this.canvas = document.createElement('canvas');
        this.canvas.width = 480;
        this.canvas.height = 360;
        this.canvas.style.position = 'absolute';
        this.canvas.style.top = '90px';
        this.canvas.style.right = '10px';
        this.canvas.style.left = 'auto';
        this.canvas.style.pointerEvents = 'none';
        this.canvas.style.zIndex = '10';
        this.canvas.style.border = '1px solid black';
        stageContainer.appendChild(this.canvas);
    }

    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height); // Clear previous visualizations

    // Define positions and sizes
    const startX = 50; // Starting X position for the bar
    const startY = 150; // Starting Y position for the bar
    const barWidth = 300; // Total width of the bar
    const barHeight = 50; // Height of the bar

    // Draw the outline of the percentage bar
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    context.strokeRect(startX, startY, barWidth, barHeight);

    // Fill the percentage portion
    context.fillStyle = '#c06c84'; // Green fill for percentage
    context.fillRect(startX, startY, (barWidth * percentage) / 100, barHeight);

    // Add percentage text inside the bar
    context.font = '20px Arial';
    context.fillStyle = 'white'; // White text
    context.textAlign = 'center';
    context.fillText(`${percentage.toFixed(2)}%`, startX + barWidth / 2, startY + barHeight / 2 + 8);

    // Add additional information about the fraction
    context.font = '16px Arial';
    context.fillStyle = 'black'; // Black text
    context.textAlign = 'center';
    context.fillText(`Fraction: ${numerator}/${denominator}`, this.canvas.width / 2, startY - 20);
  }








    


    
   
   
    
}

module.exports = Fractions;

