const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const axios = require('axios');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
let initializedPins = new Set();
let definedFunctions = new Set();
definedVariables = new Set();

class Scratch3ArduinoUnoBlocks {
    constructor(runtime) {
        this.runtime = runtime;
        codeContext = localStorage.getItem('codeContext') || 'setup';
        codeContext = localStorage.setItem('codeContext', 'setup');
    }

    getInfo() {
        return {
            id: 'arduinouno',
            color1: '#AF7AC5',
            name: formatMessage({
                id: 'arduinouno.categoryName',
                default: 'Arduino Uno',
                description: 'Arduino Uno extension category'
            }),
            blocks: [
                {
                    opcode: 'includeHeader',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.includeHeader',
                        default: 'Include header [headerFile]',
                        description: 'Include a header file'
                    }),
                    arguments: {
                        headerFile: {
                            type: ArgumentType.STRING,
                            menu: 'headerMenu',
                            defaultValue: 'Wire.h'
                        }
                    }
                },
                {
                    opcode: 'setCodeContext',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'roboticarm.setCodeContext',
                        default: 'call the fuction inside [context]',
                        description: 'Set whether to add code to setup or loop section.'
                    }),
                    arguments: {
                        context: {
                            type: ArgumentType.STRING,
                            menu: 'contextMenu',
                            defaultValue: 'setup'
                        }
                    },
                    func: 'setCodeContext'
                },
                {
                    opcode: 'defVAR',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.defVAR',
                        default: 'Define motor [varr] with pin [pinnum]',
                        description: 'Define Variable'
                    }),
                    arguments: {
                        pinnum: {
                            type: ArgumentType.STRING,
                            menu: 'digitalPortMenu',
                            defaultValue: '6'
                        },
                        varr: {
                            type: ArgumentType.STRING,
                            menu: 'varMenu1',
                            defaultValue: 'm1a'
                        }
                    }
                },
                {
                    opcode: 'digPIN',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.digPIN',
                        default: 'Set variable [varname] to digital pin [pinnum] set I/O [ipop] as [onoff]',
                        description: 'Select digital pin'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            menu: 'varMenu2',
                            defaultValue: 'v1'
                        },
                        pinnum: {
                            type: ArgumentType.STRING,
                            menu: 'digitalPortMenu',
                            defaultValue: '13'
                        },
                        ipop: {
                            type: ArgumentType.STRING,
                            menu: 'ipopMenu',
                            defaultValue: 'OUTPUT'
                        },
                        onoff: {
                            type: ArgumentType.STRING,
                            menu: 'onoffMenu1',
                            defaultValue: 'ACTIVE'
                        }
                    }
                },
                {
                    opcode: 'definePIN',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.definePIN',
                        default: 'Set digital pin [pinnum] as variable [varname] to I/O [ipop]',
                        description: 'Set digital pin'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            menu: 'varMenu2',
                            defaultValue: 'v1'
                        },
                        pinnum: {
                            type: ArgumentType.STRING,
                            menu: 'digitalPortMenu',
                            defaultValue: '13'
                        },
                        ipop: {
                            type: ArgumentType.STRING,
                            menu: 'ipopMenu',
                            defaultValue: 'OUTPUT'
                        }
                    }
                },
                {
                    opcode: 'digWrite',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.digWrite',
                        default: 'Set variable [varname] as [onoff]',
                        description: 'Set digital pin'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            menu: 'varMenu2',
                            defaultValue: 'v1'
                        },
                        onoff: {
                            type: ArgumentType.STRING,
                            menu: 'onoffMenu1',
                            defaultValue: 'ACTIVE'
                        }
                    }
                },
                {
                    opcode: 'digRead',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.digRead',
                        default: 'Read from variable [varname] and store [datatype] input value in [storevar]',
                        description: 'Read digital pin'
                    }),
                    arguments: {
                        varname: {
                            type: ArgumentType.STRING,
                            menu: 'varMenu2',
                            defaultValue: 'v1'
                        },
                        datatype: {
                            type: ArgumentType.STRING,
                            menu: 'datatypeMenu',
                            defaultValue: 'int'
                        },
                        storevar: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        }
                    }
                },
                {
                    opcode: 'varCheck',
                    blockType: BlockType.BOOLEAN,
                    text: formatMessage({
                        id: 'arduinouno.varCheck',
                        default: 'Check Varaible [storevar] == [number]',
                        description: 'Check Varaible'
                    }),
                    arguments: {
                        storevar: {
                            type: ArgumentType.STRING,
                            defaultValue: 'name_your_variable'
                        },
                        number: {
                            type: ArgumentType.STRING,
                            defaultValue: '0'
                        }
                    }
                },
                {
                    opcode: 'varblock',
                    blockType: BlockType.REPORTER,
                    text: formatMessage({
                        id: 'arduinouno.varblock',
                        default: '[var]',
                        description: 'Enter a variable name'
                    }),
                    arguments: {
                        var: {
                            type: ArgumentType.STRING,
                            defaultValue: 'v6'
                        }
                    }
                },
                // {
                //     opcode: 'delayBlock',
                //     blockType: BlockType.COMMAND,
                //     text: formatMessage({
                //         id: 'arduinouno.delayBlock',
                //         default: 'Wait [delaynum] milliseconds',
                //         description: 'Wait for a specified amount of time'
                //     }),
                //     arguments: {
                //         delaynum: {
                //             type: ArgumentType.NUMBER,
                //             defaultValue: 1000
                //         }
                //     }
                // },
                {
                    opcode: 'ifBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.ifBlock',
                        default: 'If [condition] then execute',
                        description: 'Evaluate condition and execute code'
                    }),
                    arguments: {
                        condition: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: true
                        }
                    }
                },
                {
                    opcode: 'elseifBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.elseifBlock',
                        default: 'else if [condition] then execute',
                        description: 'Evaluate condition and execute code'
                    }),
                    arguments: {
                        condition: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: true
                        }
                    }
                },
                {
                    opcode: 'elseBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.elseBlock',
                        default: 'else then execute',
                        description: 'Evaluate condition and execute code'
                    })
                },
                {
                    opcode: 'closeBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.closeBlock',
                        default: 'Close the condition block',
                        description: 'Close the condition block'
                    })
                },
                {
                    opcode: 'setSerialBaudRate',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.setSerialBaudRate',
                        default: 'Set serial baud rate to [baudrate]',
                        description: 'Set the baud rate for serial communication'
                    }),
                    arguments: {
                        baudrate: {
                            type: ArgumentType.STRING,
                            menu: 'baudrateMenu',
                            defaultValue: '9600'
                        }
                    }
                },
                {
                    opcode: 'readSerialValue',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.readSerialValue',
                        default: 'Read Serial value',
                        description: 'Read serial value from defined serial port'
                    })
                },
                {
                    opcode: 'storeSerialValue',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.storeSerialValue',
                        default: 'Store the serial value as [datatype]',
                        description: 'Read serial value from defined serial port'
                    }),
                    arguments: {
                        datatype: {
                            type: ArgumentType.STRING,
                            menu: 'datatypeMenu',
                            defaultValue: 'int'
                        }
                    }
                },
            ],
            menus: {
                digitalPortMenu: {
                    items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
                },
                ipopMenu: {
                    items: ['OUTPUT', 'INPUT']
                },
                onoffMenu1: {
                    items: ['ACTIVE', 'INACTIVE']
                },
                varMenu1: {
                    items: ['m1a', 'm1b', 'm2a', 'm2b']
                },
                headerMenu: {
                    items: ['Wire.h', 'Adafruit_PWMServoDriver.h', 'Servo.h', 'EEPROM.h']
                },
                varMenu2: {
                    acceptReporters: true,
                    items: ['v1', 'v2', 'v3', 'v4']
                },
                numMenu1: {
                    items: ['1', '2', '3', '4']
                },
                numMenu2: {
                    items: ['1', '2', '3', '4']
                },
                numMenu3: {
                    items: ['5', '6', '7', '8', '9', '10', '11', '12']
                },
                dirMenu: {
                    items: ['forward', 'backward']
                },
                stateMenu: {
                    items: ['free', 'hold']
                },
                onoffMenu2: {
                    items: ['ON', 'OFF']
                },
                contextMenu: {
                    items: ['setup', 'loop']
                },
                baudrateMenu: {
                    items: ['300', '1200', '2400', '4800', '9600', '19200', '38400', '57600', '115200', '230400', '250000', '500000', '1000000', '2000000']
                },
                datatypeMenu: {
                    items: ['int', 'float', 'char', 'long', 'double']
                }
            }
        };
    }

    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }

    // Add this method to clear loopCode
    clearLoopCode() {
        loopCode = '';
    }


    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }

    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins = new Set();
        this.clearLoopCode();
        definedVariables = new Set();
        definedFunctions = new Set();
    }

    setCodeContext(args) {
        const context = args.context;
        if (context === 'setup' || context === 'loop') {
            codeContext = context;
            // Save codeContext to local storage
            localStorage.setItem('codeContext', context);
        } else {
            console.error('Invalid context. Must be "setup" or "loop".');
        }
    }

    digPIN(args) {
        const varname = args.varname;
        const pinnum = args.pinnum;
        const ipop = args.ipop;
        let onoff = args.onoff;

        if (onoff === 'ACTIVE') {
            onoff = 'HIGH';
        } else if (onoff === 'INACTIVE') {
            onoff = 'LOW';
        }

        if (!defineCode.includes(`#define ${varname}`)) {
            defineCode += `#define ${varname} ${pinnum}\n`;
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            if (!initializedPins.has(varname)) {
                setupCode += `pinMode(${varname}, ${ipop});\n`;
                initializedPins.add(varname);
            }
            setupCode += `digitalWrite(${varname}, ${onoff});\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            if (!initializedPins.has(varname)) {
                setupCode += `pinMode(${varname}, ${ipop});\n`;
                initializedPins.add(varname);
            }
            loopCode += `digitalWrite(${varname}, ${onoff});\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    definePIN(args) {
        const varname = args.varname;
        const pinnum = args.pinnum;
        const ipop = args.ipop;
        if (!defineCode.includes(`#define ${varname}`)) {
            defineCode += `#define ${varname} ${pinnum}\n`;
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            if (!initializedPins.has(varname)) {
                setupCode += `pinMode(${varname}, ${ipop});\n`;
                initializedPins.add(varname);
            }
            // if (ipop === 'OUTPUT') {
            //     setupCode += `digitalWrite(${varname}, ${onoff});\n`;
            // }
            // else if(ipop === 'INPUT'){
            //     setupCode += `digitalRead(${varname}, ${onoff});\n`;
            // }
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            if (!initializedPins.has(varname)) {
                setupCode += `pinMode(${varname}, ${ipop});\n`;
                initializedPins.add(varname);
            }
        }
        this.accumulateCode();
        this.resetCode();
    }
    digWrite(args) {
        const varname = args.varname;
        let onoff = args.onoff;
        if (onoff === 'ACTIVE') {
            onoff = 'HIGH';
        } else if (onoff === 'INACTIVE') {
            onoff = 'LOW';
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `digitalWrite(${varname}, ${onoff});\n`;
            // else if(ipop === 'INPUT'){
            //     setupCode += `digitalRead(${varname}, ${onoff});\n`;
            // }
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `digitalWrite(${varname}, ${onoff});\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    digRead(args) {
        const varname = args.varname;
        const storevar = args.storevar;
        const datatype = args.datatype;
        if (!defineCode.includes(`${datatype} ${storevar}`)) {
            defineCode += `${datatype} ${storevar};\n`;
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `${storevar} = digitalRead(${varname});\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `${storevar} = digitalRead(${varname});\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    delayBlock(args) {
        const delaynum = args.delaynum;
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `delay(${delaynum});\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `delay(${delaynum});\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }

    varblock(args) {
        const varName = args.var;

        // Ensure the variable name is valid
        if (this.isValidVariableName(varName)) {
            // If the variable hasn't been defined yet, define it
            if (!definedVariables.has(varName)) {
                defineCode += `int ${varName};\n`;
                definedVariables.add(varName);
            }

            // Return the variable name as a string
            return varName;
        } else {
            // If the variable name is invalid, return a default or error message
            return 'invalid_var';
        }
    }

    isValidVariableName(name) {
        const validNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
        return validNameRegex.test(name);
    }
    defVAR(args) {
        const varr = args.varr;
        const pinnum = args.pinnum;
        defineCode += `#define ${varr} ${pinnum}\n`;
        this.accumulateCode();
        this.resetCode();
    }

    includeHeader(args) {
        const headerFile = args.headerFile;
        defineCode += `#include <${headerFile}>\n`;
        this.accumulateCode();
        this.resetCode();
    }
    setSerialBaudRate(args) {
        const baudrate = args.baudrate;
        if (!setupCode.includes('Serial.begin')) {
            setupCode += `Serial.begin(${baudrate});\n`;

        }
        this.accumulateCode();
        this.resetCode();
    }
    readSerialValue() {
        if (!definedFunctions.has('Serial_val')) {
            bodyCode += `int serial_val(){
                    if (Serial.available()>0){
                        int val =Serial.read();
                    return val;
                    }
                    }
                `;
            definedFunctions.add('Serial_val');
        }
        this.accumulateCode();
        this.resetCode();
    }
    storeSerialValue(args) {
        const datatype = args.datatype;  // Get the selected data type
        const codeToAdd = `${datatype} val = serial_val();\n`;  // Use the selected datatype

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    ifBlock(args) {
        const condi = args.condition
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `if(${condi}){\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `if(${condi}){\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    elseifBlock(args) {
        const condi = args.condition
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}else if(${condi}){\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}else if(${condi}){\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    elseBlock() {
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}else{\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}else{\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    closeBlock(){
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    varCheck(block) {
        const variable = block.storevar;
        const number = block.number;
    
        // Generate the condition for the if block
        return `${variable} == ${number}`;
    }
}

module.exports = Scratch3ArduinoUnoBlocks;
