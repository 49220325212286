import React, { useState, useRef, useCallback, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import ReactConsole from 'react-console-emulator';
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { FolderIcon, FileIcon, ChevronDownIcon, ChevronRightIcon, Play, Trash2, GripHorizontal, Sun, MoonStarIcon, Plus, FolderPlus } from 'lucide-react';

const MIN_EXPLORER_WIDTH = 200;
const MAX_EXPLORER_WIDTH = 600;

const DEFAULT_PYTHON_CODE = `# Online Python compiler (interpreter) to run Python online.
# Write Python 3 code in this online editor and run it.
print("Stem World")`

let suggestionsRegistered = false;

export default function PYTHONTab({ height = "800px", width = "100%", zIndex = 10, files, onFilesChange }) {
  const [userInput, setUserInput] = useState('')
  const [terminalHeight, setTerminalHeight] = useState(400)
  const [isDragging, setIsDragging] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(true)
  const [currentFile, setCurrentFile] = useState('main')
  const [isAddingFile, setIsAddingFile] = useState(false)
  const [isAddingFolder, setIsAddingFolder] = useState(false)
  const [newItemName, setNewItemName] = useState('')
  const [error, setError] = useState('')
  const [explorerWidth, setExplorerWidth] = useState(300)
  const [code, setCode] = useState(DEFAULT_PYTHON_CODE)
  const [isResizing, setIsResizing] = useState(false);
  const resizeRef = useRef(null);
  const consoleRef = useRef()
  const containerRef = useRef()
  const dragStartRef = useRef(0)
  const initialHeightRef = useRef(0)
  const editorRef = useRef(null)
  const completionProviderRef = useRef(null)

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    // Only register suggestions if they haven't been registered yet
    if (!suggestionsRegistered) {
      const suggestions = [
        {
          label: 'print',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'print(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Print objects to the text stream',
          documentation: 'Print objects to the text stream file, separated by sep and followed by end.'
        },
        {
          label: 'input',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'input(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Read a string from standard input',
          documentation: 'Read a string from standard input.'
        },
        {
          label: 'len',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'len(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Return the length of an object',
          documentation: 'Return the length (the number of items) of an object.'
        },
        {
          label: 'if',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'if ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'If statement',
          documentation: 'Execute a block of code if a condition is true.'
        },
        {
          label: 'elif',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'elif ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Else If statement',
          documentation: 'Execute a block of code if a condition is true.'
        },
        {
          label: 'else',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'else:\n\t${1}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Else statement',
          documentation: 'Execute a block of code if none of the conditions are true.'
        },
        {
          label: 'for',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'for ${1:item} in ${2:items}:\n\t${3}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'For loop',
          documentation: 'Iterate over a sequence of items.'
        },
        {
          label: 'while',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'while ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'While loop',
          documentation: 'Execute statements repeatedly as long as a condition is true.'
        },
        {
          label: 'def',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: 'def ${1:function_name}(${2:parameters}):\n\t${3:pass}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Function definition',
          documentation: 'Define a new function.'
        },
        {
          label: 'class',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: 'class ${1:ClassName}:\n\tdef __init__(self):\n\t\t${2:pass}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Class definition',
          documentation: 'Define a new class.'
        },
        {
          label: 'import',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'import ${1:module}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Import statement',
          documentation: 'Import a module.'
        }
      ];

      // Register new completion provider only if not already registered
      if (!completionProviderRef.current) {
        completionProviderRef.current = monaco.languages.registerCompletionItemProvider('python', {
          provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn
            };

            return {
              suggestions: suggestions.map(s => ({ ...s, range })),
              incomplete: false
            };
          },
          triggerCharacters: ['.']
        });

        suggestionsRegistered = true;
      }
    }

    // Configure editor settings
    editor.updateOptions({
      snippetSuggestions: 'inline',
      suggestOnTriggerCharacters: true,
      parameterHints: { enabled: true },
      quickSuggestions: { other: true, comments: false, strings: false },
      wordBasedSuggestions: true,
      suggest: {
        localityBonus: true,
        snippetsPreventQuickSuggestions: false,
        showIcons: true,
        showStatusBar: true,
        preview: true,
        filterGraceful: true
      }
    });
  };

  const handleResizeMouseDown = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  const handleResizeMouseMove = useCallback((e) => {
    if (!isResizing || !containerRef.current) return;
    
    const containerRect = containerRef.current.getBoundingClientRect();
    const maxAllowedWidth = Math.min(
      MAX_EXPLORER_WIDTH,
      containerRect.width - 400 // Leave minimum space for editor
    );
    
    const newWidth = Math.max(
      MIN_EXPLORER_WIDTH,
      Math.min(e.clientX - containerRect.left, maxAllowedWidth)
    );
    
    setExplorerWidth(newWidth);
  }, [isResizing]);

  const handleResizeMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResizeMouseMove);
      window.addEventListener('mouseup', handleResizeMouseUp);
    } else {
      window.removeEventListener('mousemove', handleResizeMouseMove);
      window.removeEventListener('mouseup', handleResizeMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleResizeMouseMove);
      window.removeEventListener('mouseup', handleResizeMouseUp);
    };
  }, [isResizing, handleResizeMouseMove, handleResizeMouseUp]);

  // Cleanup function to dispose of the completion provider
  useEffect(() => {
    return () => {
      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
        completionProviderRef.current = null;
        suggestionsRegistered = false;
      }
    };
  }, []);

  const handleEditorChange = (value) => {
    setCode(value);
    if (currentFile) {
      // Update the file content in your files state
      onFilesChange(prev => prev.map(file =>
        file.path === currentFile ? { ...file, content: value } : file
      ));
    }
  };

  const handleRunCode = async () => {
    try {
      const response = await fetch('https://emkc.org/api/v2/piston/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language: 'python',
          version: '3.10.0',
          files: [
            {
              name: 'main.py',
              content: code,
            },
          ],
          stdin: userInput,
        }),
      })

      const result = await response.json()
      const output = result.run?.output || result.run?.stderr || 'No output returned'
      consoleRef.current.pushToStdout(output)
    } catch (error) {
      consoleRef.current.pushToStdout('Error executing code: ' + error.message)
    }
  }

  const handleClearCode = () => {
    setCode('')
    consoleRef.current.clearStdout()
  }

  const handlePythonCommand = async (input) => {
    const command = input.join(' ')
    try {
      const response = await fetch('https://emkc.org/api/v2/piston/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language: 'python',
          version: '3.10.0',
          files: [
            {
              name: 'main.py',
              content: command,
            },
          ],
        }),
      })
      const result = await response.json()
      const output = result.run?.output || result.run?.stderr || 'No output returned'
      return output
    } catch (error) {
      return 'Error executing command: ' + error.message
    }
  }

  const commands = {
    run: {
      description: 'Run the code in the editor',
      usage: 'run',
      fn: handleRunCode,
    },
    python: {
      description: 'Execute a Python command',
      usage: 'python <command>',
      fn: (...args) => handlePythonCommand(args),
    },
  }

  const handleMouseDown = useCallback((e) => {
    setIsDragging(true)
    dragStartRef.current = e.clientY
    initialHeightRef.current = terminalHeight
    e.preventDefault()
  }, [terminalHeight])

  const handleMouseMove = useCallback((e) => {
    if (!isDragging || !containerRef.current) return

    const containerRect = containerRef.current.getBoundingClientRect()
    const containerHeight = containerRect.height
    const delta = dragStartRef.current - e.clientY
    const newHeight = Math.max(100, Math.min(
      containerHeight - 200,
      initialHeightRef.current + delta
    ))

    requestAnimationFrame(() => {
      setTerminalHeight(newHeight)
    })
  }, [isDragging])

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight
        setTerminalHeight(prev => Math.min(prev, containerHeight - 200))
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = 'none'
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = ''
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = ''
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  const checkDuplicateName = (name, parentPath = '') => {
    return files.some(item =>
      item.name === name && item.parentPath === parentPath
    );
  };

  const validatePythonFile = (filename) => {
    if (!filename.match(/^[a-zA-Z0-9_-]+\.py$/)) {
      setError('Invalid filename. Use only letters, numbers, underscore and dash');
      return false;
    }
    return true;
  };

  const handleAddFile = (parentPath = '') => {
    if (!newItemName) return;

    let filename = newItemName;
    if (!filename.endsWith('.py')) {
      filename += '.py';
    }

    const newPath = parentPath ? `${parentPath}/${filename}` : filename;

    if (checkDuplicateName(filename, parentPath)) {
      setError(`File "${filename}" already exists in this location`);
      return;
    }

    if (validatePythonFile(filename)) {
      onFilesChange([...files, {
        id: Date.now(),
        name: filename,
        type: 'file',
        path: newPath,
        content: '',
        parentPath
      }]);
      setNewItemName('');
      setIsAddingFile(false);
      setError('');
    }
  };

  const handleAddFolder = (parentPath = '') => {
    if (!newItemName) return;

    if (checkDuplicateName(newItemName, parentPath)) {
      setError(`Folder "${newItemName}" already exists in this location`);
      return;
    }

    const newPath = parentPath ? `${parentPath}/${newItemName}` : newItemName;

    onFilesChange([...files, {
      id: Date.now(),
      name: newItemName,
      type: 'folder',
      path: newPath,
      parentPath
    }]);
    setNewItemName('');
    setIsAddingFolder(false);
    setError('');
  };

  const handleDelete = (item) => {
    // Prevent deletion of main.py
    if (item.name === 'main.py' || item.isDefault) {
      return;
    }
    onFilesChange(files.filter(file => file !== item));
  };

  const handleDeleteFile = (fileId) => {
    // Prevent deletion of default file
    const fileToDelete = files.find(f => f.id === fileId);
    if (fileToDelete?.isDefault) {
      return;
    }

    // Delete non-default files
    const newFiles = files.filter(f => f.id !== fileId);
    onFilesChange(newFiles);
    
    // If deleted file was current, switch to main
    if (fileId === currentFile) {
      const mainFile = files.find(f => f.isDefault);
      setCurrentFile(mainFile.id);
    }
  };

  const FileExplorerTile = ({ items, level = 0, parentPath = '' }) => {
    const folders = items.filter(item => item.type === 'folder' && item.parentPath === parentPath);
    const files = items.filter(item => item.type === 'file' && item.parentPath === parentPath);

    return (
      <div className="pl-4">
        {folders.map((folder, index) => (
          <div
            key={folder.id}
            className="group relative bg-opacity-10 rounded-lg p-3 transition-all duration-300 hover:scale-105 hover:shadow-lg cursor-pointer"
            style={{
              backgroundColor: 'rgba(59, 130, 246, 0.1)',
              border: '1px solid rgba(59, 130, 246, 0.2)'
            }}
          >
            <div className="flex items-center space-x-2">
              <FolderIcon size={20} className="text-blue-500" />
              <span className="flex-grow truncate">{folder.name}</span>
              <Trash2
                size={16}
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:text-red-500"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(folder);
                }}
              />
            </div>
            <div className="mt-2">
              <FileExplorerTile
                items={items}
                level={level + 1}
                parentPath={folder.path}
              />
            </div>
          </div>
        ))}
        {files.map((file) => (
          <div
            key={file.path}
            className={`group flex items-center p-2 rounded cursor-pointer hover:bg-indigo-50 
              ${currentFile === file.path ? 'bg-indigo-100' : ''}`}
            style={{
              backgroundColor: 'rgba(99, 102, 241, 0.1)',
              border: '1px solid rgba(99, 102, 241, 0.2)'
            }}
            onClick={() => {
              setCurrentFile(file.path);
              setCode(file.content);
            }}
          >
            <div className="flex items-center space-x-2">
              <FileIcon size={20} className="text-indigo-500" />
              <span className="flex-grow truncate">{file.name}</span>
              {!file.isDefault && (
                <Trash2
                  size={16}
                  className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(file);
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const FileItem = ({ file }) => (
    <div className="flex items-center gap-2 py-1 px-2">
      <FileIcon className="h-4 w-4" />
      <span className={file.isDefault ? "text-blue-500" : ""}>{file.name}</span>
      {!file.isDefault && (
        <Trash2 
          className="h-4 w-4 ml-auto cursor-pointer hover:text-red-500" 
          onClick={() => handleDeleteFile(file.id)}
        />
      )}
    </div>
  )

  useEffect(() => {
    if (currentFile) {
      const file = files.find(f => f.id === currentFile);
      if (file) {
        setCode(file.content);
      }
    }
  }, [currentFile, files]);

  return (
    <div 
      ref={containerRef}
      className={`rounded-lg overflow-hidden shadow-lg transition-colors duration-300 ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
      }`}
      style={{
        height,
        width: "100%", // Changed from width prop to 100%
        maxWidth: "1420px", // Add max width
        zIndex,
        minHeight: '400px',
        position: 'relative'
      }}
    >
      <div className="flex h-full">
        {/* File Explorer */}
        <div
          style={{ 
            width: explorerWidth,
            minWidth: MIN_EXPLORER_WIDTH,
            maxWidth: MAX_EXPLORER_WIDTH 
          }}
          className={`flex-shrink-0 border-r relative ${
            isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'
          }`}
        >
          <div className="p-4 h-full overflow-y-auto">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-semibold">Project Files</h3>
              <div className="flex space-x-2">
                <Button
                  onClick={() => setIsAddingFile(true)}
                  variant={isDarkMode ? "outline" : "secondary"}
                  size="sm"
                  className="h-8 w-8 p-0"
                  title="New File"
                >
                  <Plus size={16} />
                </Button>
                {/* <Button
                  onClick={() => setIsAddingFolder(true)}
                  variant={isDarkMode ? "outline" : "secondary"}
                  size="sm"
                  className="h-8 w-8 p-0"
                  title="New Folder"
                >
                  <FolderPlus size={16} />
                </Button> */}
              </div>
            </div>

            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
            {(isAddingFile || isAddingFolder) && (
              <div className="mb-4">
                <input
                  type="text"
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      isAddingFile ? handleAddFile() : handleAddFolder();
                    }
                  }}
                  className={`w-full p-2 border rounded transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  }`}
                  placeholder={isAddingFile ? "filename.py" : "folder name"}
                  autoFocus
                />
              </div>
            )}

            <div className="grid grid-cols-1 gap-4">
              {files.map((item) => (
                <div
                  key={item.id}
                  onClick={() => {
                    if (item.type === 'file') {
                      setCurrentFile(item.path);
                      setCode(item.content);
                    }
                  }}
                  className={`
                    relative group p-4 rounded-lg border transition-all duration-300
                    ${isDarkMode 
                      ? 'bg-gray-700/50 border-gray-600 hover:bg-gray-700' 
                      : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                    }
                    ${item.type === 'file' ? 'cursor-pointer' : ''}
                    hover:shadow-lg hover:scale-102
                  `}
                >
                  <div className="flex items-center space-x-3">
                    {item.type === 'folder' ? (
                      <FolderIcon size={24} className={`${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                    ) : (
                      <FileIcon size={24} className={`${isDarkMode ? 'text-indigo-400' : 'text-indigo-500'}`} />
                    )}
                    <span className="truncate flex-1">{item.name}</span>
                    <Trash2
                      size={18}
                      className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item);
                      }}
                    />
                  </div>
                  
                  {item.type === 'file' && (
                    <div className="mt-2 text-xs text-gray-500">
                      Last modified: {new Date().toLocaleDateString()}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Resize Handle */}
          <div
            className={`absolute right-0 top-0 bottom-0 w-1 cursor-col-resize ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
            } hover:bg-blue-500 transition-colors duration-200 ${
              isResizing ? 'bg-blue-500' : ''
            }`}
            onMouseDown={handleResizeMouseDown}
          />
        </div>
        {/* Main Editor Area */}
        <div className="flex-grow min-w-0 flex flex-col h-full">
          <div className={`flex justify-between items-center p-3 border-b ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-gray-50'
            }`}>
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2">
                <Label htmlFor="dark-mode" className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                </Label>
                <div className="flex items-center space-x-2">
                  <Sun
                    size={18}
                    className={`transform transition-transform duration-300 hover:scale-125 hover:rotate-45 ${isDarkMode ? 'text-gray-400' : 'text-yellow-600'
                      }`}
                  />
                  <div
                    className={`relative w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'
                      }`}
                    onClick={() => setIsDarkMode(!isDarkMode)}
                  >
                    <div
                      className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-all duration-500 ${isDarkMode ? 'translate-x-6 rotate-[360deg]' : ''
                        }`}
                    ></div>
                  </div>
                  <MoonStarIcon
                    size={18}
                    className={`transform transition-transform duration-300 hover:scale-125 ${isDarkMode ? 'text-blue-700' : 'text-gray-400'
                      }`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: `calc(100% - ${terminalHeight}px - 80px)` }}>
            <Editor
              height="100%"
              language="python"
              theme={isDarkMode ? "vs-dark" : "light"}
              value={code}
              onChange={handleEditorChange}
              onMount={handleEditorDidMount}
              className="transition-opacity duration-300"
              options={{
                minimap: { enabled: false },
                fontSize: 14,
                lineNumbers: 'on',
                scrollBeyondLastLine: false,
                padding: { top: 16 },
                automaticLayout: true,
                suggestOnTriggerCharacters: true,
                quickSuggestions: true,
                snippetSuggestions: 'inline',
                parameterHints: { enabled: true },
                suggest: {
                  localityBonus: true,
                  snippetsPreventQuickSuggestions: false,
                  showIcons: true,
                  showStatusBar: true,
                  preview: true,
                }
              }}
            />
          </div>

          <div className={`border-t p-2 flex flex-wrap items-center justify-between gap-2 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-100 border-gray-300'
            }`}>
            <div className="flex gap-2 flex-shrink-0">
              <Button
                onClick={handleRunCode}
                disabled={code.trim() === ''}
                variant={isDarkMode ? "default" : "secondary"}
                size="sm"
                className="group relative overflow-hidden"
              >
                <div className="absolute inset-0 group-hover:bg-white/20 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
                <Play
                  size={16}
                  className="mr-1 transition-all duration-300 group-hover:scale-110 group-hover:rotate-90"
                />
                Run
              </Button>
              <Button
                onClick={handleClearCode}
                variant={isDarkMode ? "destructive" : "outline"}
                size="sm"
                className="transform transition-transform duration-200 ease-out hover:-rotate-6 will-change-transform"
              >
                <Trash2 size={16} className="mr-1" />
                Clear
              </Button>
            </div>
            <input
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Enter input for the code (if required)..."
              className={`
                flex-1 min-w-[200px] px-2 py-1 rounded text-sm 
                focus:outline-none relative
                before:absolute before:inset-0 before:rounded before:p-[2px]
                before:bg-gradient-to-r before:from-blue-500 before:via-purple-500 before:to-pink-500
                before:animate-gradient-x focus:before:opacity-100 before:opacity-0
                transition-all duration-300
                ${isDarkMode
                  ? 'bg-gray-700 text-gray-200 placeholder-gray-400'
                  : 'bg-white text-gray-900 placeholder-gray-500 border border-gray-300'
                }
              `}
            />
          </div>

          <div
            className={`h-2 cursor-row-resize flex items-center justify-center border-t border-b select-none ${isDarkMode
              ? 'bg-gray-800 hover:bg-gray-700 border-gray-700'
              : 'bg-gray-200 hover:bg-gray-300 border-gray-300'
              } ${isDragging ? (isDarkMode ? 'bg-gray-700' : 'bg-gray-300') : ''}`}
            onMouseDown={handleMouseDown}
            style={{ touchAction: 'none' }}
          >
            <GripHorizontal size={16} className={isDarkMode ? 'text-gray-500' : 'text-gray-600'} />
          </div>

          <div
            style={{ height: `${terminalHeight}px` }}
            className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}
          >
            <ReactConsole
              commands={commands}
              noCommandFound="Command not found. Try 'python <command>' or 'run'"
              welcomeMessage="Welcome to the Python Terminal. Type 'help' for available commands."
              promptLabel=">>>"
              readOnly={false}
              autoFocus={false}
              ref={consoleRef}
              className={isDarkMode ? 'console-dark-mode' : 'console-light-mode'}
              style={{
                height: '100%',
                fontFamily: 'monospace',
                fontSize: '14px',
                padding: '1rem',
                overflowY: 'auto',
              }}
            />
          </div>
        </div>
      </div>
      <style jsx global>{`
        @keyframes gradient-x {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient-x {
          animation: gradient-x 3s linear infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  )
}