import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';

import { ComingSoonTooltip } from '../coming-soon/coming-soon.jsx';

// import LoginDropdown from '../../components/library/library.jsx'
import LoginDropdown from '../menu-bar/login-dropdown.jsx';
import UnityGameModal from './unityGameModal.jsx'; // Adjust the path as needed
import Arvrdocs from './ar-vr-docs.jsx';
import Appdocs from './appDev/app.jsx';
import Design from './design/design-docs.jsx';
import Appdev from './appdev/appdev.jsx';

import Robotics from './Robotics.jpg';
import artificialintelligence from './artificialintelligence.jpg';
import math from './math.jpg';
import geography from './Geography.jpg';
import iot from './iot.jpg';
import ar from './ar.jpg';
import printing from './printing.jpg';
import codingImg from './codingimg.jpg';
import cyber from './cyber.png';
import drons from './drons.jpg';
import gameEnvironmentImage from './gameEnvironmentImage.jpg';
import mlImage from './mlImage.jpg';
import allExtensions from './allExtensions.jpg';
import mitAppInventorImage from './mitAppInventorImage.png';
import { LogIn, ChevronLeftCircleIcon, LogOut, Code, PuzzleIcon as PuzzlePiece, X } from 'lucide-react';
import Button from '../button/button.jsx';
// import MLEnv from '../../App.js'; 
import MLEnv from './MLEnv.js'
import styles from './library.css';
import apiClient from '../../utils/apiClient';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = { tag: 'all', intlLabel: messages.allTag };
const tagListPrefix = [ALL_TAG];

// const [selectedButton, setSelectedButton] = useState('');

class LibraryComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handlePlayingEnd',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef',
            'handleBackButtonClick',
            'handleButtonMouseEnter', // Add this line
            'handleButtonMouseLeave',
            'signInButtonOnClick',
            'handleSignInButtonClick',
            'handleSignOutButtonClick',
            'isUserLoggedIn',
            'handleML',
            'handleRoboticsOptionSelect'


        ]);
        this.state = {
            playingItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            loaded: true,
            buttonsVisible: true,
            showData: false,
            hoveredButton: null, // Add this line
            selectedButton: '', // New state variable
            dropdownVisible: false,
            dropdownManuallyOpened: false,
            showModal: false, // New state for controlling the new modal
            isUnityModalOpen: false,
            isArvrModalOpen: false,
            isAppModalOpen: false,
            isDesignModalOpen: false,
            isHandleModalOpen: false,
            isSignInButtonDisabled: true,
            isLoggedIn: Boolean(localStorage.getItem('accessToken')),
            isRoboticsModalOpen: false,
            selectedRoboticsOption: null // To track which option user selects
        };

        this.dropdownRef = React.createRef(); // Create ref for dropdown
        this.filteredDataRef = React.createRef(); // Create ref for filtered data
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }




    // Toggle dropdown visibility on button click
    handleSignInButtonClick() {

        // Your existing sign-in logic here
        this.setState(prevState => ({
            dropdownVisible: !prevState.dropdownVisible,
            dropdownManuallyOpened: true
        }));
        localStorage.setItem('isLoginModalOpen', true);

    }

    // Handle clicks outside the dropdown
    // handleClickOutside(event) {
    //     if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
    //         this.setState({ dropdownVisible: false });
    //     }
    // }
    handleUnityModalToggle = (isOpen) => {
        this.setState({ isUnityModalOpen: isOpen });
    }
    handleMlModal = (isOpen) => {
        this.setState({ isHandleModalOpen: isOpen })
    }
    handleArvrModalToggle = (isOpen) => {
        this.setState({ isArvrModalOpen: isOpen });
    }
    handleAppModalToggle = (isOpen) => {
        this.setState({ isAppModalOpen: isOpen });
    }
    handleDesignModalToggle = (isOpen) => {
        this.setState({ isDesignModalOpen: isOpen })
    }
    handleappdevModalToggle = (isOpen) => {
        this.setState({ isappdevModalOpen: isOpen })
    }
    // Add event listener for clicks outside when the component mounts
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        setTimeout(() => {
            this.setState({ loaded: true });
        });
        setTimeout(() => {
            this.setState({ isSignInButtonDisabled: false });
            console.log("Sign in button enabled");
        }, 2000);
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }

    handleML(button) {
        this.setState(prevState => ({
            isHandleModalOpen: !prevState.isHandleModalOpen
        }));

    };

    // Remove event listener when the component unmounts
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleButtonClick(button) {
        if (button === 'Game Development') {
            this.setState({ isUnityModalOpen: true });
        }
        else if (button === 'AR | VR') {
            this.setState({ isArvrModalOpen: true });
            console.log("ar vr button clicked")
        }
        else if (button === 'MIT App Inventor') {
            this.setState({ isAppModalOpen: true });
            console.log("ar vr button clicked")
        }
        else if (button === '3D Printings') {
            this.setState({ isDesignModalOpen: true });
            console.log("ar vr button clicked")
        }
        else if (button === 'MITAppInventor') {
            this.setState({ isappdevModalOpen: true });
            console.log("ar vr button clicked")
        }
        else if (button === 'Robotics') {
            this.setState({ isRoboticsModalOpen: true });
        }
        else {
            this.setState({
                buttonsVisible: false,
                showData: button,
                selectedButton: button,
            });
        }
        // // Add a 2-second delay before showing the dropdown
        // setTimeout(() => {
        //     this.setState({ dropdownVisible: true });
        // }, 1000);
    }




    handleBackButtonClick() { // Add this method
        console.log('Back button clicked'); // Log when back button is clicked
        this.setState({
            buttonsVisible: true,
            showData: false,
            selectedButton: '',
            showModal: false, // Ensure modal is closed
            dropdownVisible: false, // Hide the dropdown when going back
            isUnityModalOpen: false,
            isArvrModalOpen: false,
            isAppModalOpen: false,
            isDesignModalOpen: false
        });
    }

    showNewModal() {
        this.setState({ showModal: true });
        console.log('Modal is open');
    }


    handleCloseDropdown = () => {
        this.setState({ dropdownVisible: false });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loaded: true });
        });
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }


    async handleSelect(extensionId) {
        const login = this.isUserLoggedIn();
        if (login === true) {
            console.log('Selected Extension ID:', extensionId);
            const selectedItem = this.getFilteredData().find(item => item.extensionId === extensionId);
            console.log('Selected Item:', selectedItem);
            if (selectedItem) {
                this.handleClose();
                this.props.onItemSelected(selectedItem);
            } else {
                console.error('Invalid selection:', extensionId);
            }
        }
        else {
            this.setState(prevState => ({
                dropdownVisible: !prevState.dropdownVisible,
            }));
        }
    }

    handleClose() {

        this.props.onRequestClose();
        // // Close the modal and ensure the dropdown remains hidden
        this.setState({
            showModal: false,  // Close the modal
            dropdownVisible: false // Ensure the dropdown is closed
        });
    }
    handleTagClick(tag) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: '',
                selectedTag: tag.toLowerCase()
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: '',
                playingItem: null,
                selectedTag: tag.toLowerCase()
            });
        }
    }
    handleMouseEnter(id) {
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave(id) {
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    handlePlayingEnd() {
        if (this.state.playingItem !== null) {
            this.setState({
                playingItem: null
            });
        }
    }
    handleFilterChange(event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
                selectedTag: ALL_TAG.tag
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: event.target.value,
                playingItem: null,
                selectedTag: ALL_TAG.tag
            });
        }
    }
    handleButtonMouseEnter(button) {
        this.setState({ hoveredButton: button });
    }

    handleButtonMouseLeave() {
        this.setState({ hoveredButton: null });
    }
    handleFilterClear() {
        this.setState({ filterQuery: '' });
    }

    signInButtonOnClick() {
        this.setState({ dropdownVisible: false });
    }

    handleClickOutside = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            this.setState({ dropdownVisible: false });
        }
    };

    handleRoboticsOptionSelect = (option) => {
        this.setState({
            selectedRoboticsOption: option,
            isRoboticsModalOpen: false
        }, () => {
            // Filter extensions based on selected option
            if (option === 'python') {
                this.setState({
                    buttonsVisible: false,
                    showData: 'PythonRobotics',
                    selectedButton: 'PythonRobotics'
                });
            } else if (option === 'scratch') {
                this.setState({
                    buttonsVisible: false,
                    showData: 'ScratchRobotics',
                    selectedButton: 'Robotics'
                });
            }
        });
    };

    renderButtonContent() {
        const { showData } = this.state;
        return contentMap[showData] || null;
    }

    isUserLoggedIn() {
        return Boolean(localStorage.getItem('accessToken'));
    }

    async handleSignOutButtonClick() {
        try {
            await apiClient.post('/auth/logout');
            localStorage.removeItem('accessToken');  // Clear access token from local storage
            //   window.location.href = '/login';  
            this.setState({
                isLoggedIn: false,
                dropdownVisible: true
            });
        } catch (error) {
            console.error('Error during logout:', error);
            // Still redirect to login page even if the server call fails
            localStorage.removeItem('accessToken');
            this.setState({
                isLoggedIn: false,
                dropdownVisible: true
            });
        }
    }

    getFilteredData() {
        if (!this.state.showData) return [];
        if (this.state.selectedTag === 'all') {
            if (!this.state.filterQuery) return this.props.data;
            let res = this.props.data.filter(dataItem => (
                (dataItem.tags || [])
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                            dataItem.name : this.props.intl.formatMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n')
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ));
            console.log(res);
            return res;
        }
        return this.props.data.filter(dataItem => (
            dataItem.tags &&
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(this.state.selectedTag) !== -1
        ));
    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    render() {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}

            >
                {this.state.isHandleModalOpen && (
                    <MLEnv
                        isOpen={this.state.isHandleModalOpen}
                        onClose={this.handleML}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.9)', // Darker overlay
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}
                    >
                        {/* Modal content */}
                        <div>Welcome to the Full-Screen ML Environment</div>
                    </MLEnv>

                )}
                <Button
                    className="absolute right-4 top-2.5 bg-blue-700 hover:bg-blue-600 text-white px-4 py-2 rounded-full"
                    onClick={this.isUserLoggedIn() ? this.handleSignOutButtonClick : this.handleSignInButtonClick}
                >
                    <span className="flex items-center justify-center">
                        {this.isUserLoggedIn() ? (
                            <>
                                <LogOut className="mr-2 inline-block" size={20} />
                                <span className="text-sm font-semibold">Logout</span>
                            </>
                        ) : (
                            <>
                                <LogIn className="mr-2 inline-block" size={20} />
                                <span className="text-sm font-semibold">Sign In</span>
                            </>
                        )}
                    </span>
                </Button>


                {/* Only show dropdown if not logged in and dropdown is visible */}
                {!this.isUserLoggedIn() && this.state.dropdownVisible && (
                    <LoginDropdown
                        isOpen={this.state.dropdownVisible}
                        onClose={this.handleCloseDropdown}
                    />
                )}
                <div>

                    {!this.state.buttonsVisible && (
                        <button
                            className={classNames(styles.backButton, "bg-blue-700 rounded-full")}
                            onClick={this.handleBackButtonClick}
                        >

                            <span className="flex items-center justify-center text-white">
                                <ChevronLeftCircleIcon className="mr-2 leading-none inline-block" size={20} />
                                <span className='text-base font-semibold'>
                                    Back
                                </span>
                            </span>
                        </button>
                    )}


                    {this.state.buttonsVisible && (
                        <>
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6 bg-sky-200">

                                {/*  All Extensions Section */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('All Extensions Section')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'All Extensions Section' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={allExtensions} alt="All Extensions Section" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2"> All Extensions/Projects</div>
                                    <p className="text-gray-600 mb-4">
                                        Explore fun and educational All Extensions-Projects to enhance your learning experience.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('All Extensions Section')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                                {/* coding card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Coding')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{ transform: this.state.hoveredButton === 'Coding' ? 'scale(1.05)' : 'scale(1)', transition: 'transform 0.3s' }}
                                >
                                    <img src={codingImg} alt="AI | ML" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Coding Enviroments</div>
                                    <p className="text-gray-600 mb-4">
                                        Dive into Coding!
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Coding')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                                {/* Robotics Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Robotics')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{ transform: this.state.hoveredButton === 'Robotics' ? 'scale(1.05)' : 'scale(1)', transition: 'transform 0.3s' }}
                                >
                                    <img src={Robotics} alt="Robotics" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Robotics</div>
                                    <p className="text-gray-600 mb-4">
                                        Explore the exciting world of robotics!
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Robotics')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                                {/* artificial-intelligence Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('AI | ML')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{ transform: this.state.hoveredButton === 'AI | ML' ? 'scale(1.05)' : 'scale(1)', transition: 'transform 0.3s' }}
                                >
                                    <img src={artificialintelligence} alt="AI | ML" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Artificial intelligence</div>
                                    <p className="text-gray-600 mb-4">
                                        Dive into Artificial Intelligence and Machine Learning!
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('AI | ML')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                                {/* ML Environment Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Machine Learning')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'Machine Learning' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={mlImage} alt="Machine Learning" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">ML Environment</div>
                                    <p className="text-gray-600 mb-4">
                                        Dive into the world of machine learning and its applications.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleML('ML Environment')}
                                    >
                                        Learn More
                                    </button>

                                </div>

                                {/* IoT | Electronics Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden "
                                    onMouseEnter={() => this.handleButtonMouseEnter('IoT | Electronics')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'IoT | Electronics' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={iot} alt="IoT | Electronics" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">IoT | Electronics</div>
                                    <p className="text-gray-600 mb-4">
                                        Explore the world of connected devices and innovative electronics.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('IoT | Electronics')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* AR | VR Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('AR | VR')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'AR | VR' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={ar} alt="AR | VR" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">AR | VR</div>
                                    <p className="text-gray-600 mb-4">
                                        Dive into the immersive world of Augmented and Virtual Reality.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('AR | VR')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* 3D Printings Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('3D Printings')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === '3D Printings' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={printing} alt="3D Printings" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">3D Printings</div>
                                    <p className="text-gray-600 mb-4">
                                        Create stunning 3D models and learn the art of 3D printing.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('3D Printings')}
                                    >
                                        Learn More
                                    </button>
                                </div>


                                {/* MIT App Inventor Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('MITAppInventor')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'MITAppInventor' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={mitAppInventorImage} alt="MIT App Inventor" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">App Development</div>
                                    <p className="text-gray-600 mb-4">
                                        Explore how to build apps easily using  App Development platform.
                                    </p>
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('MIT App Inventor')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* Mathematics Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Mathematics')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{ transform: this.state.hoveredButton === 'Mathematics' ? 'scale(1.05)' : 'scale(1)', transition: 'transform 0.3s' }}
                                >
                                    <img src={math} alt="Mathematics" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Mathematics</div>
                                    <p className="text-gray-600 mb-4">
                                        Sharpen your skills with advanced math challenges.
                                    </p>


                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Mathematics')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* Geography Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden "
                                    onMouseEnter={() => this.handleButtonMouseEnter('Geography')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'Geography' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={geography} alt="Geography" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Geography</div>
                                    <p className="text-gray-600 mb-4">
                                        Explore the world with exciting geography challenges.
                                    </p>
                                    {this.state.hoveredButton === 'Geography' && (
                                        <ComingSoonTooltip place="top" tooltipId="geography-coming-soon">
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <button
                                                    disabled // Disable the button
                                                >
                                                    {/* Optionally, you can add any content or leave it empty */}
                                                </button>
                                            </div>
                                        </ComingSoonTooltip>
                                    )}

                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Geography')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* Cybersecurity Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden "
                                    onMouseEnter={() => this.handleButtonMouseEnter('Cybersecurity')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'Cybersecurity' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={cyber} alt="Cybersecurity" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Cybersecurity</div>
                                    <p className="text-gray-600 mb-4">
                                        Protect yourself and your data in the digital world.
                                    </p>
                                    {this.state.hoveredButton === 'Cybersecurity' && (
                                        <ComingSoonTooltip place="top" tooltipId="cybersecurity-coming-soon">
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <button
                                                    disabled // Disable the button
                                                >
                                                    {/* Optionally, you can add any content or leave it empty */}
                                                </button>
                                            </div>
                                        </ComingSoonTooltip>
                                    )}

                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Cybersecurity')}
                                    >
                                        Learn More
                                    </button>
                                </div>

                                {/* Game Development */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Game Development')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'Game Development' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={gameEnvironmentImage} alt="Game Development" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Game Development</div>
                                    <p className="text-gray-600 mb-4">
                                        Immerse yourself in interactive game design and environments.
                                    </p>
                                    {this.state.hoveredButton === 'Game Development' && (
                                        <ComingSoonTooltip place="top" tooltipId="gamedev-coming-soon">
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <button
                                                    disabled // Disable the button
                                                >
                                                    {/* Optionally, you can add any content or leave it empty */}
                                                </button>
                                            </div>
                                        </ComingSoonTooltip>
                                    )}

                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition"
                                        onClick={() => this.handleButtonClick('Game Development')}
                                    >
                                        Learn More
                                    </button>
                                </div>


                                {/* Drones Card */}
                                <div
                                    className="bg-white shadow-lg rounded-lg p-6 text-center relative overflow-hidden"
                                    onMouseEnter={() => this.handleButtonMouseEnter('Drones')}
                                    onMouseLeave={this.handleButtonMouseLeave}
                                    style={{
                                        transform: this.state.hoveredButton === 'Drones' ? 'scale(1.05)' : 'scale(1)',
                                        transition: 'transform 0.3s'
                                    }}
                                >
                                    <img src={drons} alt="Drones" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                    <div className="text-xl font-bold text-indigo-600 mb-2">Drones</div>
                                    <p className="text-gray-600 mb-4">
                                        Discover the technology behind drones and their applications.
                                    </p>

                                    {/* Centered Coming Soon Tooltip when hovering over the card */}
                                    {this.state.hoveredButton === 'Drones' && (
                                        <ComingSoonTooltip place="top" tooltipId="drones-coming-soon">
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <button
                                                    disabled // Disable the button
                                                >
                                                    {/* Optionally, you can add any content or leave it empty */}
                                                </button>
                                            </div>
                                        </ComingSoonTooltip>
                                    )}

                                    {/* Learn More Button for Drones Card */}
                                    <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600 transition mt-4"
                                        onClick={() => this.handleButtonClick('Drones')} // Replace with your click handler
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>

                        </>
                    )}

                    {(this.props.filterable || this.props.tags) && (
                        <div
                            className={classNames(
                                styles.filterBar,
                                'bg-gradient-to-r from-cyan-600 via-blue-900 to-cyan-600' // Apply gradient to filterBar
                            )}
                        >
                            {this.props.filterable && (
                                <Filter
                                    className={classNames(
                                        styles.filterBarItem,
                                        styles.filter
                                    )}
                                    filterQuery={this.state.filterQuery}
                                    inputClassName={styles.filterInput}
                                    placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                    onChange={this.handleFilterChange}
                                    onClear={this.handleFilterClear}
                                />
                            )}
                            {this.props.filterable && this.props.tags && (
                                <Divider className={classNames(styles.filterBarItem, styles.divider)} />
                            )}
                            {this.props.tags && (
                                <div className={styles.tagWrapper}>
                                    {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                        <TagButton
                                            active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                            className={classNames(
                                                styles.filterBarItem,
                                                styles.tagButton,
                                                tagProps.className
                                            )}
                                            key={`tag-button-${id}`}
                                            onClick={() => this.handleTagClick(tagProps.tag)}
                                            {...tagProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    {!this.state.buttonsVisible && (
                        <div
                            className={classNames(styles.libraryScrollGrid, {
                                [styles.withFilterBar]: this.props.filterable || this.props.tags
                            })}
                            ref={this.setFilteredDataRef}
                        >
                            {this.state.loaded ? this.getFilteredData()
                                .filter(dataItem => this.state.selectedButton === '' || dataItem?.category?.toLowerCase() === this.state.selectedButton.toLowerCase()) // Add this line
                                .map((dataItem, index) => (
                                    <LibraryItem
                                        bluetoothRequired={dataItem.bluetoothRequired}
                                        collaborator={dataItem.collaborator}
                                        description={dataItem.description}
                                        disabled={dataItem.disabled}
                                        extensionId={dataItem.extensionId}
                                        featured={dataItem.featured}
                                        hidden={dataItem.hidden}
                                        iconMd5={dataItem.costumes ? dataItem.costumes[0].md5ext : dataItem.md5ext}
                                        iconRawURL={dataItem.rawURL}
                                        icons={dataItem.costumes}
                                        id={dataItem.extensionId}
                                        tags={dataItem.tags}
                                        insetIconURL={dataItem.insetIconURL}
                                        internetConnectionRequired={dataItem.internetConnectionRequired}
                                        isPlaying={this.state.playingItem === index}
                                        key={typeof dataItem.name === 'string' ? dataItem.name : dataItem.rawURL}
                                        name={dataItem.name}
                                        showPlayButton={this.props.showPlayButton}
                                        onMouseEnter={this.handleMouseEnter}
                                        onMouseLeave={this.handleMouseLeave}
                                        onSelect={this.handleSelect}
                                    />
                                )) : (
                                <div className={styles.spinnerWrapper}>
                                    <Spinner
                                        large
                                        level="primary"
                                    />
                                </div>
                            )}
                        </div>
                    )}

                </div>
                <UnityGameModal
                    isOpen={this.state.isUnityModalOpen}
                    onClose={() => this.handleUnityModalToggle(false)}
                />
                <Arvrdocs
                    isOpen={this.state.isArvrModalOpen}
                    onClose={() => this.handleArvrModalToggle(false)}
                />
                <Appdocs
                    isOpen={this.state.isAppModalOpen}
                    onClose={() => this.handleAppModalToggle(false)}
                />
                <Design
                    isOpen={this.state.isDesignModalOpen}
                    onClose={() => this.handleDesignModalToggle(false)}

                />
                <Appdev
                    isOpen={this.state.isappdevModalOpen}
                    onClose={() => this.handleappdevModalToggle(false)}
                />
                {this.state.isRoboticsModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fadeIn">
                        <div className="bg-white p-8 rounded-lg text-center shadow-xl max-w-md w-full mx-4 transform transition-all duration-300 ease-in-out animate-scaleIn">
                            <h2 className="text-3xl font-bold mb-6 text-gray-800 flex items-center justify-center">
                                <Code className="w-8 h-8 mr-2 text-blue-500" />
                                Choose Robotics Programming Mode
                            </h2>
                            <div className="flex flex-col sm:flex-row justify-around space-y-4 sm:space-y-0 sm:space-x-6 mb-8">
                                <button
                                    className="px-8 py-4 text-lg bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 shadow-md flex items-center justify-center"
                                    onClick={() => this.handleRoboticsOptionSelect('python')}
                                >
                                    <Code className="w-6 h-6 mr-2" />
                                    Python
                                </button>
                                <button
                                    className="px-8 py-4 text-lg bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 shadow-md flex items-center justify-center"
                                    onClick={() => this.handleRoboticsOptionSelect('scratch')}
                                >
                                    <PuzzlePiece className="w-6 h-6 mr-2" />
                                    Scratch
                                </button>
                            </div>
                            <button
                                className="px-6 py-2 text-sm bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 flex items-center justify-center mx-auto"
                                onClick={() => this.setState({ isRoboticsModalOpen: false })}
                            >
                                <X className="w-4 h-4 mr-1" />
                                Close
                            </button>
                        </div>
                    </div>
                )}

            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func.isRequired,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired,
    title: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool,
    onRequestClose: PropTypes.func,
    intl: intlShape
    // signInButtonText: PropTypes.string, // New prop
    // signInButtonOnClick: PropTypes.func // New prop

};

LibraryComponent.defaultProps = {
    filterable: true,
    showPlayButton: true,
    // signInButtonText: 'Sign In', // Default text
    // signInButtonOnClick: () => { } // Default no-op function
};

export default injectIntl(LibraryComponent);