
let defineCode = '';
let setupCode = '';
let loopCode = '';
let bodyCode = '';

const codeGeneration = {
    // Method to accumulate code from different extensions
    accumulateCode(define, body, setup, loop) {
        defineCode += define.trim() + '\n';
        setupCode += setup.trim() + '\n';
        loopCode += loop.trim() + '\n';
        bodyCode += body.trim() + '\n';
    },

    // Method to generate the final combined code
    generateFinalCode() {
        return `
        ${defineCode.trim()}
        void setup() {
            ${setupCode.trim()}
        }
        void loop() {
            ${loopCode.trim()}
        }
        ${bodyCode.trim()}
    `;
    },

    // Method to reset the code (if needed)
    resetCode() {
        defineCode = '';
        setupCode = '';
        loopCode = '';
        bodyCode = '';
    }
};

module.exports = codeGeneration;